import React from 'react';
import {connect} from 'react-redux';
import pick from 'lodash/pick';
import {Comment, CommentForm} from '../../../comments/2022/Comments.jsx';

class FeedPostCommentsArea extends React.PureComponent {
  state = {
    newComment: '',
  };

  _deleteComment = (comment) => {
    if (!confirm('Are you sure?')) {
      return;
    }
    this.props.cmd.deleteComment(comment, this.props.target);
  };

  _fetchPrevious = (event) => {
    this.props.cmd.fetchPreviousComments(this.props.target);
  };

  _newCommentChanged = (event) => {
    this.setState({newComment: event.target.value});
  };

  _submitContent = (event) => {
    event.preventDefault();
    if (!this.state.newComment.trim()) {
      return;
    }
    this.props.cmd.comment(this.props.target, this.state.newComment);
    this.setState({newComment: ''});
  };

  render() {
    const {cmd, comments, commentContextActions} = this.props;
    if (!comments) {
      return null;
    }
    const hasPrevious = comments.earliest < comments.hi;
    if (comments.comments.length === 0 && !comments.commentable && !hasPrevious) {
      return null;
    }
    return (
      <div className="comment-container">
        <div className="comment-content">
          {
            hasPrevious ? (
              <div className="Comment">
                <a onClick={this._fetchPrevious}>
                  View Previous Comments
                </a>
              </div>
            ): null
          }
          {
            comments.comments.map(comment => {
              return (
                <Comment
                  key={comment.id}
                  comment={comment}
                  contextActions={commentContextActions[comment._links.self.href]}
                  onDelete={this._deleteComment}
                  onFetchContextActions={cmd.fetchContextActions}
                  />
              );
            })
          }
          {
            comments.commentable ? (
              <CommentForm value={this.state.newComment} onChange={this._newCommentChanged} onSubmit={this._submitContent} />
            ): null
          }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  let keys = [];
  if (ownProps.comments.comments) {
    keys = ownProps.comments.comments.map(c => c._links.self.href);
  }
  return {
    commentContextActions: pick(state.feed.contextActions, keys),
  };
}

export default connect(mapStateToProps, null)(FeedPostCommentsArea);
