import React from 'react';

import './ArticleCard.css';

class ArticleCard extends React.PureComponent {
  render() {
    const {article, nextLink} = this.props;
    return (
      <>
        <div className="position-relative my-4">
          <h5 className="text-uppercase">{article.subtitle}</h5>
          <p className="m-0">{article._links.team.title}</p>
          { nextLink.title !== 'Article' ? (
            <div className="Flag">
              {nextLink.title}
            </div>
          ) : null }
        </div>
        {
          article.picture_url ? (
            <div className="Post-contentImg AspectRatioBox AspectRatioBox--ratio-16-9">
              <img src={article.picture_url} />
            </div>
          ): null
        }
        <h4 className="PostArticleBox-title">{article.title}</h4>
        <div className="PostArticleBox-body">
          {
            article.location_str ? (
              <span className="PostArticleBox-location">{article.location_str} &mdash; </span>
            ): null
          }
          {article.first_paragraph}
        </div>
      </>
    );
  }
}

export default ArticleCard;
