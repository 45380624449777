import Media from "react-media";
import React from "react";
import "./ScoreCard.css";

class ScoreCard extends React.PureComponent {
  render() {
    const { scoreEdit } = this.props;
    let homeTeamAvatar = null;
    let homeTeamName = null;
    let homeTeamRecord = null;
    if (scoreEdit._embedded.home_team) {
      homeTeamAvatar = (
        <a
          className="Team--logo"
          href={scoreEdit._embedded.home_team._links.self.href}
        >
          <img
            src={scoreEdit._embedded.game._links.home_team_profile_picture.href}
          />
        </a>
      );
      if (
        scoreEdit._embedded.home_team.team_type === "highschool" ||
        scoreEdit._embedded.home_team.team_type === "college"
      ) {
        homeTeamName = (
          <a
            className="FeedScoreCardTeam-name"
            href={scoreEdit._embedded.home_team._links.self.href}
          >
            {scoreEdit._embedded.home_team.school_name}
          </a>
        );
      } else {
        homeTeamName = (
          <a
            className="FeedScoreCardTeam-name"
            href={scoreEdit._embedded.home_team._links.self.href}
          >
            {scoreEdit._embedded.home_team.display_name}
          </a>
        );
      }
      if (scoreEdit.home_team_record) {
        homeTeamRecord = (
          <span className="FeedScoreCardTeam-record">
            {" "}
            ({scoreEdit.home_team_record})
          </span>
        );
      }
    } else {
      homeTeamAvatar = (
        <span className="Team--logo">
          <img
            src={scoreEdit._embedded.game._links.home_team_profile_picture.href}
          />
        </span>
      );
      homeTeamName = (
        <span className="FeedScoreCardTeam-name">
          {scoreEdit.home_team_name}
        </span>
      );
    }

    let awayTeamAvatar = null;
    let awayTeamName = null;
    let awayTeamRecord = null;
    if (scoreEdit._embedded.away_team) {
      awayTeamAvatar = (
        <a
          className="Team--logo"
          href={scoreEdit._embedded.away_team._links.self.href}
        >
          <img
            src={scoreEdit._embedded.game._links.away_team_profile_picture.href}
          />
        </a>
      );
      if (
        scoreEdit._embedded.away_team.team_type === "highschool" ||
        scoreEdit._embedded.away_team.team_type === "college"
      ) {
        awayTeamName = (
          <a
            className="FeedScoreCardTeam-name"
            href={scoreEdit._embedded.away_team._links.self.href}
          >
            {scoreEdit._embedded.away_team.school_name}
          </a>
        );
      } else {
        awayTeamName = (
          <a
            className="FeedScoreCardTeam-name"
            href={scoreEdit._embedded.away_team._links.self.href}
          >
            {scoreEdit._embedded.away_team.display_name}
          </a>
        );
      }
      if (scoreEdit.away_team_record) {
        awayTeamRecord = (
          <span className="FeedScoreCardTeam-record">
            {" "}
            ({scoreEdit.away_team_record})
          </span>
        );
      }
    } else {
      awayTeamAvatar = (
        <span className="Team--logo">
          <img
            src={scoreEdit._embedded.game._links.away_team_profile_picture.href}
          />
        </span>
      );
      awayTeamName = (
        <span className="FeedScoreCardTeam-name">
          {scoreEdit.away_team_name}
        </span>
      );
    }

    let leagueGroup = null;
    if (scoreEdit._links.away_league || scoreEdit._links.home_league) {
      let awayLeague = scoreEdit._links.away_league;
      let homeLeague = scoreEdit._links.home_league;
      if (awayLeague && homeLeague && awayLeague.href == homeLeague.href) {
        homeLeague = null;
      }
      leagueGroup = (
        <Media query="(min-width: 992px)">
          {(matches) => {
            return matches ? (
              <div className="FeedScoreCard-leagues d-flex">
                <div className="col">
                  <div className="d-flex align-items-center">
                    <img
                      className="FeedScoreCard-leaguesIcon"
                      src="/static/img/scoreboard.svg"
                    />
                    <div className="btn-txt">League Scoreboards</div>
                  </div>
                </div>
                <div className="col-6 text-end">
                  {awayLeague ? (
                    <a
                      className="btn u-btnDefaultLinkOutlineColor FeedScoreCard-leagueBtn"
                      href={awayLeague.href}
                    >
                      {awayLeague.title}
                    </a>
                  ) : null}
                  {homeLeague ? (
                    <a
                      className="btn u-btnDefaultLinkOutlineColor FeedScoreCard-leagueBtn"
                      href={homeLeague.href}
                    >
                      {homeLeague.title}
                    </a>
                  ) : null}
                </div>
              </div>
            ) : (
              <div className="FeedScoreCard-leagues">
                <div className="col text-center">
                  <div className="d-flex justify-content-center align-items-center">
                    <img
                      className="FeedScoreCard-leaguesIcon"
                      src="/static/img/scoreboard.svg"
                    />
                    <div className="btn-txt">League Scoreboards</div>
                  </div>
                </div>
                <div className="col-12 mt-4 text-center">
                  {awayLeague ? (
                    <a
                      className="btn u-btnDefaultLinkOutlineColor FeedScoreCard-leagueBtn"
                      href={awayLeague.href}
                    >
                      {awayLeague.title}
                    </a>
                  ) : null}
                  {homeLeague ? (
                    <a
                      className="btn u-btnDefaultLinkOutlineColor FeedScoreCard-leagueBtn"
                      href={homeLeague.href}
                    >
                      {homeLeague.title}
                    </a>
                  ) : null}
                </div>
              </div>
            );
          }}
        </Media>
      );
    }
    return (

      <>
        <h4 className="mt-3">
          {scoreEdit._embedded.game.display_gender} {scoreEdit._embedded.game.level} {scoreEdit._embedded.game.sport.name}
          <div className="GameDate">{
            new Date(scoreEdit._embedded.game.datetime).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })
          }</div>
          <div className="d-sm-none">
            {scoreEdit.final ? `Final` : scoreEdit.extended_info_summary}
          </div>
        </h4>
        <div className="d-flex justify-content-between flex-wrap">
          <div className="Team--home col-6 col-sm-4 text-center">
            {awayTeamAvatar}
            <div className="Game-teamInfo text-center">
              <h5>{awayTeamName}</h5>
              <p className="Team-location">
              {scoreEdit._embedded.game.away_team_display_location}
              </p>
              {awayTeamRecord}
            </div>
            {/*
            golf sport_id is 8
            In golf lowest score wins
            */}
            <div className={`Team-score d-sm-none${scoreEdit._embedded.game.sport.id === 8 ? scoreEdit.away_team_score < scoreEdit.home_team_score ? ' win' : scoreEdit.away_team_score === scoreEdit.home_team_score ? ' tied' : '' : scoreEdit.away_team_score > scoreEdit.home_team_score ? ' win' : scoreEdit.away_team_score === scoreEdit.home_team_score ? ' tied' : ''}`}>
              {scoreEdit.away_team_score}
            </div>
          </div>
          <div className="col-sm-4 d-none d-sm-flex flex-column justify-content-center align-items-center gap-2">
            <div className="d-sm-flex justify-content-center align-items-center">
              <div className={`Team-score${scoreEdit._embedded.game.sport.id === 8 ? scoreEdit.away_team_score < scoreEdit.home_team_score ? ' win' : scoreEdit.away_team_score === scoreEdit.home_team_score ? ' tied' : '' : scoreEdit.away_team_score > scoreEdit.home_team_score ? ' win' : scoreEdit.away_team_score === scoreEdit.home_team_score ? ' tied' : ''}`}>
                {scoreEdit.away_team_score}
              </div>
              &ensp;<strong>&#8212;</strong>&ensp;
              <div className={`Team-score${scoreEdit._embedded.game.sport.id === 8 ? scoreEdit.home_team_score < scoreEdit.away_team_score ? ' win' : scoreEdit.home_team_score === scoreEdit.away_team_score ? ' tied' : '' : scoreEdit.home_team_score > scoreEdit.away_team_score ? ' win' : scoreEdit.home_team_score === scoreEdit.away_team_score ? ' tied' : ''}`}>
                {scoreEdit.home_team_score}
              </div>
            </div>
            {scoreEdit.final ? `Final` : scoreEdit.extended_info_summary}
          </div>

          <div className="Team--away col-6 col-sm-4 text-center">
            {homeTeamAvatar}
            <div className="Game-teamInfo text-center">
              <h5>{homeTeamName}</h5>
              <p className="Team-location">
              {scoreEdit._embedded.game.home_team_display_location}
              </p>
              {homeTeamRecord}
            </div>
            <div className={`Team-score d-sm-none${scoreEdit._embedded.game.sport.id === 8 ? scoreEdit.home_team_score < scoreEdit.away_team_score ? ' win' : scoreEdit.home_team_score === scoreEdit.away_team_score ? ' tied' : '' : scoreEdit.home_team_score > scoreEdit.away_team_score ? ' win' : scoreEdit.home_team_score === scoreEdit.away_team_score ? ' tied' : ''}`}>
              {scoreEdit.home_team_score}
            </div>
          </div>
        </div>

        {scoreEdit.notes ? (
          <div className="FeedScoreCard-notes">{scoreEdit.notes}</div>
        ) : null}

        {
          // leagueGroup
        }
      </>
    );
  }
}

export default ScoreCard;
