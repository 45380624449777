import React from "react";
import ngDateFilter from "../../ngDateFilter";
import * as util from '../../util';

import "./Comments.css";

export class Comment extends React.PureComponent {
  state = {
    opened: false
  }

  componentDidMount() {
    document.addEventListener('click', this._handleBodyClick, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this._handleBodyClick, true);
  }

  _handleBodyClick = (event) => {
    if (!this.state.opened) {
      return;
    }
    if (!util.isAncestor(event.target, this._dropdown)) {
      event.preventDefault();
      this.setState({ opened: false });
      return;
    }
  };

  _delete = () => {
    const { comment, onDelete } = this.props;
    onDelete(comment);
  };

  _fetchContextActions = () => {
    this.setState({
      opened: !this.state.opened,
    })
    const { comment, onFetchContextActions } = this.props;
    onFetchContextActions(
      comment._links.context_actions.href,
      comment._links.self.href
    );
  };

  render() {
    const { comment } = this.props;
    return (
      <div className="Comment" key={comment.id} ref={n => this._dropdown = n}>
        <CommentDropdown
          comment={comment}
          contextActions={this.props.contextActions}
          onDelete={this._delete}
          show={this.state.opened}
          onFetch={this._fetchContextActions}
        />
        <div className="CommentForm">
          <div className="CommentAuthorAvatar">
            {comment._embedded && comment._embedded.author ? (<img src={comment._embedded.author._links.profile_picture.href} />): null}
          </div>
          <div className="CommentContent">
            <div className="CommentText">
              {
                comment._embedded && comment._embedded.author ? (
                  <>
                    <a className="defaultColorLink" target="_top" href={comment._embedded.author._links.self.href}>
                      {comment._embedded.author.display_name}
                    </a>{" "}
                  </>
                ): null
              }
              <span className="CommentTime">
                {ngDateFilter(comment.created_at, "MMM d, y h:mm a")}
              </span>
              <div className="CommentDisplay" dangerouslySetInnerHTML={{ __html: comment.content_html }} ></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class CommentDropdown extends React.PureComponent {
  render() {
    const { comment, contextActions, show } = this.props;
    return (
      <div className="CommentDropdown dropdown">
        <a className="CommentDropdown-toggle MoreMenu" data-toggle="dropdown" onClick={this.props.onFetch} >
          <i className="fa-regular fa-ellipsis txt-middle" style={{ lineHeight: "inherit" }} ></i>
        </a>
        <ul className={`dropdown-menu${ show ? ' show' : '' }`}>
          {!contextActions ? <li className="loading-gif"></li> : null}
          {contextActions ? (
            <li>
              <a className="Report" data-user-voice-report-abuse={comment._links.self.href}>
                <i className="fa fa-flag"></i> Report Abuse
              </a>
            </li>
          ) : null}
          {contextActions && contextActions.delete ? (
            <li>
              <a onClick={this.props.onDelete}>
                <i className="fa fa-times"></i> Delete
              </a>
            </li>
          ) : null}
        </ul>
      </div>
    );
  }
}

export function CommentForm(props) {
  const disabled = props.submitting || props.smsVerificationRequired;
  const textareaRef = React.useRef(null);

  React.useLayoutEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = `${Math.max(
        textareaRef.current.scrollHeight,
        0
      )}px`;
    }
  }, [props.value]);

  const changeHeight = () => {
    textareaRef.current.style.height = 0
  }
  return (
    <div className="Comment">
      <form className="CommentForm" onSubmit={props.onSubmit}>
          {props.replySectionText ? (
            <div className="MessageTextareaWrapper row">
                <textarea
                  ref={textareaRef}
                  value={props.value}
                  disabled={disabled}
                  onChange={props.onChange}
                  className={`${props.value ? "AdjustHeight": ""} form-control mb-2 col-12`}
                  placeholder="Reply here">
                </textarea>
              <button className={`btn p-0 py-2 text-center col-4 ms-auto `} onClick={changeHeight} disabled={disabled || !props.value} type="submit" >
                Send
              </button>
            </div>
          ) : (
            <div className="Input-Group d-flex row">
              <input
                type="text"
                className="col-8 col-md-9 col-lg-10"
                placeholder="Enter a comment"
                value={props.value}
                disabled={disabled}
                onChange={props.onChange}
              />
              <button className="btn btn-outline-default col-4 col-md-3 col-lg-2" disabled={disabled} type="submit" >
                Post
              </button>
            </div>
          )}
        {props.smsVerificationRequired ? (
          <div className="help-block text-danger">
            You are required to{" "}
            <a className="text-primary" target="_top" href={"/verify_cell_phone?return_to=" + window.location.pathname} >
              verify your cell phone number
            </a>{" "}
            before you are able to make a comment.
          </div>
        ) : null}
      </form>
    </div>
  );
}
