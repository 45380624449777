// singular version of redux's bindActionCreators
export function bindActionCreator(actionCreator, dispatch) {
  return function () {
    dispatch(actionCreator.apply(null, arguments));
  };
}

export function blockNonDigits (event) {
  // block printable characters that are non-digits
  if (event.charCode >= 48 && event.charCode <= 57) {
    return;
  } else if (String.fromCharCode(event.charCode) != '') {
    event.preventDefault();
  }
}

export function blockNonDecimal (event) {
  // block printable characters that are non-digits
  if (event.charCode >= 48 && event.charCode <= 57) {
    return;
  } else if (event.charCode === 46) { // period
    return;
  } else if (String.fromCharCode(event.charCode) != '') {
    event.preventDefault();
  }
}

export function castArray(value) {
  if (!arguments.length) {
    return [];
  }
  return Array.isArray(value) ? value : [value];
}

// given an array, return the indexes to fetch items in ascending order
// given an object, return the keys to fetch values in ascending order
// compare is an optional callback (a, b) => bool that must return:
//   <0 when item a is less than b
//    0 when item a is equal to b
//   >0 when item a is greater than b
export function grade(arr, compare) {
  let isObject = arr !== null && typeof arr === 'object' && !Array.isArray(arr);
  let results = undefined;
  if (isObject) {
    results = Object.keys(arr);
  } else if (Array.isArray(arr)) {
    results = new Array(arr.length);
    for (let i=0; i < results.length; i++) {
      results[i] = i;
    }
  } else {
    throw new Error('must be array or object');
  }
  if (compare) {
    results.sort((i, j) => {
      const x = compare(arr[i], arr[j]);
      if (x != 0) {
        return x;
      }
      return i - j;
    });
    return results;
  }
  // reproduces the ordering of Array.sort when it comes to NaN, null
  // and undefined
  results.sort((i, j) => {
    const a = arr[i];
    const b = arr[j];
    if (a !== a) {
      return 1;
    }
    if (b !== b) {
      return -1;
    }
    if (a === undefined) {
      return 1;
    }
    if (b === undefined) {
      return -1;
    }
    if (a === null) {
      return 1;
    }
    if (b === null) {
      return -1;
    }
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return i - j;
  });
  return results;
}

export function formattedBytesIEC(bytes) {
  const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB']
  if (bytes === 0) {
    return 'n/a';
  }
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) {
    return `${bytes} ${sizes[i]}`;
  }
  return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`;
}


export function  centsToFormattedUSD (amount) {
  return (amount / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
}


// returns true if node is a parent element of match
export function isAncestor(node, match) {
  if (!node || !match) {
    return false;
  }
  if (node === match) {
    return false;
  }
  let parent = node.parentElement;
  while (parent !== null) {
    if (parent === match) {
      return true;
    }
    parent = parent.parentElement;
  }
  return false;
}

export function isNonEmptyObject(o) {
  if (!isObject(o)) {
    return false;
  }
  for (let property in o) {
    return true;
  }
  return false;
}

// isObject should only return true for a "plain" object
// isObject({}) => true
// isObject(null) => false
// isObject(undefined) => false
// isObject(3) => false
// isObject([1]) => false
// isObject(new Date()) => false
export function isObject(o) {
  return o !== null && typeof o === 'object' && Object.getPrototypeOf(o) === Object.prototype;
}

export function isPlatformIos () {
  switch (navigator.platform) {
  case 'iPhone':
  case 'iPad':
  case 'iPod':
    return true;
  }
  return false;
}

export function kindaValidCellPhone (phone) {
  if (!phone) {
    return false;
  }
  phone = phone.trim();
  if (!phone) {
    return false;
  }
  phone = phone.replace(/[^\d]/g, '');
  if (phone.length < 10) {
    return false;
  }
  if (phone.length >= 11 && phone[0] != '1') {
    return false;
  }
  return true;
}

export function kindaValidEmail (email) {
  if (!email) {
    return false;
  }
  email = email.trim();
  if (!email) {
    return false;
  }
  if (!email.match(/[^@]+@[^@]+\.[^@]+/)) {
    return false;
  }
  return true;
}

export function moveCursorToEnd (el) {
  if (!el) {
    return;
  }
  const len = el.value.length;
  el.focus();
  el.setSelectionRange(len, len);
}

export function normalizePhoneUs (value, previousValue) {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d\*]/g, '');
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3) {
      return onlyNums + '-';
    }
    if (onlyNums.length === 6) {
      return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3) + '-';
    }
  }
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3);
  }
  return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3, 6) + '-' + onlyNums.slice(6, 10);
}

const NTH_SUFFIXES = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];
export function nth(n) {
  let num = undefined;
  if (n === null) {
    return '';
  } else if (typeof(n) === 'number') {
    num = n;
  } else if (typeof(n) === 'string') {
    if (n.match(/^\d+$/)) {
      num = Number(n);
    }
  }
  if (num === undefined) {
    return n;
  }
  const numStr = num.toString();
  if (num === 11 || num === 12 || num === 13) {
    return numStr + 'th';
  }
  const suffix = NTH_SUFFIXES[num % 10];
  return numStr + suffix;
}

// given an array, return the values at indexes
// also works with objects, where indexes are keys instead
export function pick(array, indexes) {
  const output = [];
  for (let i of indexes) {
    output.push(array[i]);
  }
  return output;
}

export function possessiveName (s) {
  if (!s) {
    return s;
  }
  if (s.charAt(s.length - 1) != 's') {
    return s + '\'s';
  } else {
    return s + '\'';
  }
}

export function zeroPad (s, pad) {
  const number = parseInt(s);
  if (isNaN(number)) {
    return s;
  }
  const N = Math.pow(10, pad);
  return number < N ? String(N + number).slice(1) : String(number);
}
