import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';

import * as FeedReducers from './feed_redux/reducers.js';
import * as FeedCommands from './feed_redux/commands.js';
import FeedContainer from './FeedContainer.jsx';

export default function mountFeed(targetElement, feedComponent, feedUrl) {
  const reducer = combineReducers({
    feed: combineReducers(FeedReducers),
  });
  const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
  const store = createStoreWithMiddleware(reducer);
  ReactDOM.render(
    <Provider store={store}>
      <FeedContainer feedComponent={feedComponent} />
    </Provider>
  , targetElement);
  store.dispatch(FeedCommands.fetchPosts(feedUrl));
  return store;
}
