import $ from 'jquery';
import React from 'react';
import mapTemplates from './tmplMapper.jsx';
import * as Tmpl from './FeedItemsDesktop.jsx';

import './feed.css';

const NEW_POSTS_DELAY = 10000;

class Feed extends React.Component {
  state = {};

  componentDidMount() {
    const zoneHeight = 300;
    $(document).on('scroll.feed', () => {
      let st = $(window).scrollTop();
      let dh = $(document).height();
      let wh = $(window).height() + zoneHeight;
      if (st >= (dh - wh)) {
        this.props.cmd.fetchOlderPosts();
      }
    });
    this._timeout = setTimeout(this._checkForNewPosts, NEW_POSTS_DELAY);
  }

  componentWillUnmount() {
    $(document).off('scroll.feed');
    clearTimeout(this._timeout);
  }

  _checkForNewPosts = () => {
    this.props.cmd.fetchNewPostsCount();
    this._timeout = setTimeout(this._checkForNewPosts, NEW_POSTS_DELAY);
  };

  _onChange = () => {
    this.setState({});
  };

  render() {
    let children = mapTemplates(Tmpl, {
      cmd: this.props.cmd,
      comments: this.props.comments,
      contextActions: this.props.contextActions,
      embeddedObjects: this.props.embeddedObjects,
      likes: this.props.likes,
      modal: this.props.modal,
      myLikes: this.props.myLikes,
      posts: this.props.posts,
    });
    if (children.length === 0 && this.props.fetching) {
      children = (
        <></>
      )
    }
    if (children.length === 0) {
      children = (
        <div className="NoPosts--card fst-italic shadow-sm">
          There are no posts yet.
        </div>
      )
    }
    let newPostsAlert = null;
    if (this.props.newPosts > 0) {
      let message = '';
      if (this.props.newPosts > 20) {
        message = 'There are 20+ new posts.';
      } else if (this.props.newPosts > 1) {
        message = `There are ${this.props.newPosts} new posts.`;
      } else {
        message = 'There is a new post.';
      }
      newPostsAlert = (
        <div className="alert alert-info text-center" style={{fontWeight: 'bold'}}>
          <a onClick={this.props.cmd.fetchNewerPosts}>
            {message}
          </a>
        </div>
      );
    }
    let loading = null;
    if (this.props.fetching) {
      loading = (
        <div className="col-12 p-5 text-center">
          <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
          <span className="">Loading&hellip;</span>
        </div>
      );
    }
    return (
      <div className="main_post overflow-hidden">
        {newPostsAlert}
        {children}
        {loading}
      </div>
    );
  }
}

export default Feed;
