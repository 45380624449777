import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

class Portal extends React.Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    className: PropTypes.string,
  };

  componentDidMount() {
    this._open(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (this._node && this.props.className !== nextProps.className) {
      this._node.className = nextProps.className;
    }
    this._open(nextProps);
  }

  componentWillUnmount() {
    this._close();
  }

  _close = () => {
    if (this._node) {
      document.body.removeChild(this._node);
    }
    this._node = null;
    this.forceUpdate();
  };

  _open = (props) => {
    if (!this._node) {
      this._node = document.createElement('div');
      if (props.className) {
        this._node.className = props.className;
      }
      this._node.style.paddingBottom = "80px"
      document.body.appendChild(this._node);
    }
    this.forceUpdate();
  };

  render() {
    if (this._node) {
      return ReactDOM.createPortal(this.props.children, this._node);
    }
    return null;
  }
}

export default Portal;
