import React from 'react';

class FeedPostByline extends React.PureComponent {
  render() {
    let post = this.props.post;
    let description = this.props.description;
    let subjectLine = this.props.verb;

    if (description) {
      subjectLine = description;
    } else if (!subjectLine && !(post.actor_id === post.subject_id && post.actor_type === post.subject_type)) {
      subjectLine = (
        <>
          {' posted on '}
          <a href={post._links.subject.href}>{post.subject_display_name}</a>
        </>
      );
    }
    return (
      <p className="Post-by">
        {
          post._links.actor ? (
            <a className="postby-name" href={post._links.actor.href}>{post.actor_display_name}</a>
          ): (
            <span className="postby-name">{post.actor_display_name}</span>
          )
        }
        {' '}{subjectLine}
      </p>
    );
  }
}

export default FeedPostByline;
