export function isAndroid() {
  return navigator.userAgent.match(/Android/) !== null;
}

export function isIos() {
  return navigator.userAgent.match(/iPhone|iPad|iPod/) !== null;
}

export function isMobile() {
  return navigator.userAgent.match(/Android|iPad|Mobile|webOS/) !== null;
}

export function supportAjaxUploadProgressEvents() {
  const xhr = new XMLHttpRequest();
  return !! (xhr && ('upload' in xhr) && ('onprogress' in xhr.upload));
}
