import keyMirror from 'keymirror';

const Actions = keyMirror({
  COMMENT_DELETED: null,
  COMMENTS_RECEIVED: null,
  CONTEXT_ACTIONS_REQUESTED: null,
  CONTEXT_ACTIONS_RECEIVED: null,
  ITEM_DESCRIPTION_UPDATED: null,
  ITEM_LIKED: null,
  ITEM_LIKING: null,
  ITEM_SHOWCASED: null,
  ITEM_UNLIKED: null,
  ITEM_UNSHOWCASED: null,
  ITEM_VIEWED: null,
  NEW_POSTS_UNSEEN: null,
  POST_DELETED: null,
  POSTS_RECEIVED: null,
  POSTS_REQUESTED: null,
  PREVIOUS_COMMENTS_RECEIVED: null,
  MODAL_CLOSED: null,
  MODAL_OPENED: null,
  USER_MUTED: null,
  USER_UNMUTED: null,
  VIDEO_PLAYED: null,
});

export default Actions;
