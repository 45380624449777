import React from 'react';
import ErrorBoundary from '../../misc/ErrorBoundary';

export default function mapTemplates (Tmpl, options) {
  const {cmd, comments, contextActions, embeddedObjects, likes, modal, myLikes, posts} = options;
  return posts.map(post => {
    let component = null;
    const props = {
      key: post.id,
      post: post,
      cmd: cmd,
      contextActions: contextActions[post._links.context_actions.href],
    };
    switch (post.activity_type) {
    case '':
    case null:
    case undefined:
      component = Tmpl.FeedPostBasic;
      props.comments = comments[post._links.self.href];
      props.liked = !!myLikes[post._links.self.href];
      props.likes = likes[post._links.self.href];
      break;
    case 'Article':
      component = Tmpl.FeedPostArticle;
      props.comments = comments[post.activity._links.self.href];
      props.liked = !!myLikes[post.activity._links.self.href];
      props.likes = likes[post.activity._links.self.href];
      break;
    case 'GameCancellation':
      component = Tmpl.FeedPostGameCancellation;
      break;
    case 'GameChangeLog':
      component = Tmpl.FeedPostGameChangeLog;
      break;
    case 'GameScoreEdit':
      component = Tmpl.FeedPostGameScoreEdit;
      break;
    case 'Mvp':
      component = Tmpl.FeedPostMvp;
      props.comments = comments[post.activity._links.self.href];
      props.liked = !!myLikes[post.activity._links.self.href];
      props.likes = likes[post.activity._links.self.href];
      break;
    case 'PictureTag':
      component = Tmpl.FeedPostPhotoTag;
      props.comments = comments[post.activity._links.picture.href];
      props.modal = modal;
      props.picture = embeddedObjects[post.activity._links.picture.href];
      props.creator = embeddedObjects[props.picture._links.creator.href];
      props.liked = !!myLikes[props.picture._links.self.href];
      props.likes = likes[props.picture._links.self.href];
      props.onModalClosed = cmd.closeModal;
      props.onModalOpened = cmd.openModal;
      break;
    case 'Team':
      component = Tmpl.FeedPostTeamCreated;
      break;
    case 'TeamMember':
      component = Tmpl.FeedPostTeamMemberJoined;
      break;
    case 'ThankYouVideo':
      component = Tmpl.FeedPostThankYouVideo;
      props.thankYou = post.activity;
      if (post.activity._links.video_link) {
        props.videoLink = embeddedObjects[post.activity._links.video_link.href];
        props.creator = embeddedObjects[props.videoLink._links.creator.href];
        props.comments = comments[props.videoLink._links.self.href];
        props.liked = !!myLikes[props.videoLink._links.self.href];
        props.likes = likes[props.videoLink._links.self.href];
      } else {
        props.video = embeddedObjects[post.activity._links.video.href];
        props.creator = embeddedObjects[props.video._links.creator.href];
        props.comments = comments[post.activity._links.video.href];
        props.video = embeddedObjects[post.activity._links.video.href];
        props.liked = !!myLikes[props.video._links.self.href];
        props.likes = likes[props.video._links.self.href];
      }
      props.modal = modal;
      props.onModalClosed = cmd.closeModal;
      props.onModalOpened = cmd.openModal;
      break;
    case 'Upload':
      component = Tmpl.FeedPostPhoto;
      props.comments = comments[post.activity._links.self.href];
      props.modal = modal;
      props.picture = embeddedObjects[post.activity._links.self.href];
      props.creator = embeddedObjects[props.picture._links.creator.href];
      props.liked = !!myLikes[props.picture._links.self.href];
      props.likes = likes[props.picture._links.self.href];
      props.onModalClosed = cmd.closeModal;
      props.onModalOpened = cmd.openModal;
      break;
    case 'Video':
      component = Tmpl.FeedPostVideo;
      props.comments = comments[post.activity._links.self.href];
      props.video = embeddedObjects[post.activity._links.self.href];
      props.creator = embeddedObjects[props.video._links.creator.href];
      props.liked = !!myLikes[props.video._links.self.href];
      props.likes = likes[props.video._links.self.href];
      props.modal = modal;
      props.onModalClosed = cmd.closeModal;
      props.onModalOpened = cmd.openModal;
      break;
    case 'VideoLink':
      component = Tmpl.FeedPostVideoLink;
      props.comments = comments[post.activity._links.self.href];
      props.modal = modal;
      props.videoLink = embeddedObjects[post.activity._links.self.href];
      props.creator = embeddedObjects[props.videoLink._links.creator.href];
      props.liked = !!myLikes[props.videoLink._links.self.href];
      props.likes = likes[props.videoLink._links.self.href];
      props.onModalClosed = cmd.closeModal;
      props.onModalOpened = cmd.openModal;
      break;
    case 'VideoLinkTag':
      component = Tmpl.FeedPostVideoLinkTag;
      props.comments = comments[post.activity._links.video_link.href];
      props.modal = modal;
      props.videoLink = embeddedObjects[post.activity._links.video_link.href];
      props.creator = embeddedObjects[props.videoLink._links.creator.href];
      props.liked = !!myLikes[props.videoLink._links.self.href];
      props.likes = likes[props.videoLink._links.self.href];
      props.onModalClosed = cmd.closeModal;
      props.onModalOpened = cmd.openModal;
      break;
    case 'VideoTag':
      component = Tmpl.FeedPostVideoTag;
      props.modal = modal;
      props.video = embeddedObjects[post.activity._links.video.href];
      props.creator = embeddedObjects[props.video._links.creator.href];
      props.liked = !!myLikes[props.video._links.self.href];
      props.likes = likes[props.video._links.self.href];
      props.onModalClosed = cmd.closeModal;
      props.onModalOpened = cmd.openModal;
      break;
    case 'VideoVital':
      component = Tmpl.FeedPostVideoVital;
      props.videoVital = post.activity;
      if (post.activity._links.video_link) {
        props.videoLink = embeddedObjects[post.activity._links.video_link.href];
        props.creator = embeddedObjects[props.videoLink._links.creator.href];
        props.comments = comments[props.videoLink._links.self.href];
        props.liked = !!myLikes[props.videoLink._links.self.href];
        props.likes = likes[props.videoLink._links.self.href];
      } else {
        props.video = embeddedObjects[post.activity._links.video.href];
        props.creator = embeddedObjects[props.video._links.creator.href];
        props.comments = comments[props.video._links.self.href];
        props.video = embeddedObjects[props.video._links.self.href];
        props.liked = !!myLikes[props.video._links.self.href];
        props.likes = likes[props.video._links.self.href];
      }
      props.modal = modal;
      props.onModalClosed = cmd.closeModal;
      props.onModalOpened = cmd.openModal;
      break;
    default:
      console.warn('Unimplemented activity type:', post.id, post.activity_type);
      break;
    }
    if (component) {
      return (<ErrorBoundary key={post.id}>{React.createElement(component, props)}</ErrorBoundary>);
    }
    return null;
  });
}
