import $ from 'jquery';
import React from 'react';
import TagEditor from './TagEditor.js';

class TagEditorContainer extends React.Component {
  static defaultProps = {
    allowTagAll: true,
  };

  state = {
    tags: [],
    editable: undefined,
    submitting: false,
    searchUrl: undefined,
    tagAllNoun: undefined,
    tagAllUrl: undefined,
  };

  componentDidMount() {
    this._getTags();
  }

  _getTags = () => {
    $.getJSON(this.props.source).done(data => {
      this.setState({
        tags: data.tags,
        editable: data.editable,
        submitting: false,
        searchUrl: data.search,
        tagAllNoun: data.tag_all_athletes_noun,
        tagAllUrl: data.tag_all_athletes,
      });
    });
  };

  _onAdd = (result) => {
    $.post(this.props.source, result).done(data => {
      result.removable = true;
      let cloned = this.state.tags.slice(0);
      cloned.push(result);
      this.setState({
        tags: cloned,
      });
    });
  };

  _onRemove = (idx) => {
    let params = $.extend({}, this.state.tags[idx]);
    params._method = 'DELETE';
    $.post(this.props.source, params).done(() => {
      let cloned = this.state.tags.slice(0);
      cloned.splice(idx, 1);
      this.setState({
        tags: cloned,
      });
    });
  };

  _onTagAll = () => {
    if (!this.state.tagAllUrl || this.state.submitting) {
      return;
    }
    this.setState({submitting: true});
    if (confirm('Are you sure? Tags entered this way can only be removed one by one.')) {
      $.post(this.state.tagAllUrl).done(() => {
        this._getTags();
      }).fail(jqXHR => {
        this.setState({submitting: false});
      });
    }
  };

  render() {
    return (
      <TagEditor
        disabled={!this.state.editable}
        tags={this.state.tags}
        tagAllNoun={this.state.tagAllNoun}
        searchUrl={this.state.searchUrl}
        submitting={this.state.submitting}
        onAdd={this._onAdd}
        onRemove={this._onRemove}
        onTagAll={(this.state.tagAllUrl && this.props.allowTagAll) ? this._onTagAll : null}
        />
    );
  }
}

export default TagEditorContainer;
