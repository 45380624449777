import React from "react";
import "./MvpCard.css";

class MvpCard extends React.PureComponent {
  render() {
    const { mvp } = this.props;
    return (
      <>
        <div className="MVP-profile row">
          <a className="col-12 col-md-6 col-lg-4" href={mvp._links.self.href}>
            <img
              src={mvp._links.team_member["data-avatar"]}
              alt={mvp._links.team_member.title}
            />
            <h4 className="MVP--name">{mvp._links.team_member.title}</h4>
          </a>
          <div className="col MVP-athleteInfo">
            <div className="MVP-profileInfo flex-fill u-borderBottom">
              <p className="MVP-gameScore">{mvp.event_summary}</p>
              <p className="MVP-levelAndSport">{mvp._links.team.title}</p>
            </div>
            <a href={mvp._links.self.href}>
              <h5>Performance</h5>
            </a>
            <p>{mvp.description}</p>
            <a href={mvp._links.self.href} className="Post-pageLink">Read More</a>
          </div>
        </div>
      </>
    );
  }
}

export default MvpCard;
