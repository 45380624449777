import $ from 'jquery';
import PropTypes from 'prop-types';
import React from 'react';
import './BigModal.css';

function debounce (func, wait, immediate) {
  var timeout;
  return function() {
    var context = this, args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  };
}

class BigModal extends React.Component {
  static propTypes = {
    content: PropTypes.element.isRequired,
    contentHeight: PropTypes.number,
    contentWidth: PropTypes.number,
    onCloseRequested: PropTypes.func,
    onPagerNext: PropTypes.func,
    onPagerPrev: PropTypes.func,
    sidebar: PropTypes.element.isRequired,
    showCloser: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    showCloser: true,
  };

  state = {};

  componentDidMount() {
    this._onWindowResize = debounce(this._onWindowResize, 250);
    $(document.body).addClass('u-noscroll');
    window.addEventListener('resize', this._onWindowResize, false);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._onWindowResize, false);
    $(document.body).removeClass('u-noscroll');
  }

  _close = (event) => {
    event.stopPropagation();
    if (this.props.onCloseRequested) {
      this.props.onCloseRequested();
    }
  };

  _hasPagerControls = () => {
    return this.props.onPagerNext && this.props.onPagerPrev;
  };

  _onTouchMove = (event) => {
    event.preventDefault();
  };

  _onWindowResize = () => {
    this.forceUpdate();
  };

  render() {
    // const margin = 40;
    // const maxWidth = window.innerWidth - (2 * margin);
    // const maxHeight = window.innerHeight - (2 * margin);
    // const minimumHeight = 520;
    // const minimumWidth = 900;
    // const sidebarWidth = 320;
    // const maxWidth = window.innerWidth * 0.8;
    // const maxHeight = window.innerHeight * 0.8;
    // let width = maxWidth;
    // let height = maxHeight;
    // if (this.props.contentHeight && this.props.contentWidth) {
    //   width = Math.min(this.props.contentWidth + sidebarWidth, width);
    //   height = Math.min(this.props.contentHeight, height);
    // }
    // width = Math.max(minimumWidth, width);
    // height = Math.max(minimumHeight, height);
    // let bigModalContentStyle = {
    //   width: width,
    //   height: height,
    // };
    // let bigModalStageWrapperStyle = {
    //   width: width - sidebarWidth,
    //   lineHeight: String(height) + 'px',
    // };
    let pagerNext = null;
    let pagerPrev = null;
    if (this._hasPagerControls()) {
      pagerNext = (
        <a className="BigModalPager BigModalPager--next" onClick={this.props.onPagerNext}>
          <div className="BigModalPagerControl"><span className="fa fa-arrow-circle-o-right"></span></div>
        </a>
      );
      pagerPrev = (
        <a className="BigModalPager BigModalPager--prev" onClick={this.props.onPagerPrev}>
          <div className="BigModalPagerControl"><span className="fa fa-arrow-circle-o-left"></span></div>
        </a>
      );
    }
    let closer = null;
    if (this.props.showCloser) {
      closer = (
        <a className="BigModalCloser" onClick={this._close} aria-label="Close Modal">&times;</a>
      );
    }
    return (
      <div className="BigModal">
        <div className="BigModalInner">
          <div className="BigModalContentWrapper container">
            {closer}
            <div className="BigModalContent row" style={{overflowY: "scroll"}}>
              <div className="BigModalStageWrapper col-12 col-lg ">
                <div className="BigModalStage">
                  {pagerNext}
                  {pagerPrev}
                  {this.props.contentOverlay}
                  {this.props.content}
                </div>
              </div>
              <div className="BigModalSidebarWrapper col-12">
                {this.props.sidebar}
              </div>
            </div>
          </div>
        </div>
        <div className="BigModalBackdrop" onClick={this._close} onTouchMove={this._onTouchMove}></div>
      </div>
    );
  }
}

export default BigModal;
