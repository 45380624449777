import React from 'react';
import {connect} from 'react-redux';
import pick from 'lodash/pick';
import ngDateFilter from '../../../../ngDateFilter';

import './FeedModalSidebarCommentsArea.css';

class FeedModalSidebarCommentsArea extends React.PureComponent {
  state = {
    newComment: '',
  };

  _deleteComment = (comment) => {
    if (!confirm('Are you sure?')) {
      return;
    }
    this.props.cmd.deleteComment(comment, this.props.target);
  };

  _fetchPrevious = (event) => {
    this.props.cmd.fetchPreviousComments(this.props.target);
  };

  _newCommentChanged = (event) => {
    this.setState({newComment: event.target.value});
  };

  _submitContent = (event) => {
    event.preventDefault();
    if (!this.state.newComment.trim()) {
      return;
    }
    this.props.cmd.comment(this.props.target, this.state.newComment);
    this.setState({newComment: ''});
  };

  render() {
    const {cmd, comments, commentContextActions} = this.props;
    if (!comments) {
      return null;
    }
    const hasPrevious = comments.earliest < comments.hi;
    if (comments.comments.length === 0 && !comments.commentable && !hasPrevious) {
      return null;
    }
    return (
      <div className="BigModalSidebarComments-area">
        {
          hasPrevious ? (
            <div className="BigModalSidebarComment">
              <div className="reply_txt">
                <p className="pr">
                  <a onClick={this._fetchPrevious}>
                    View Previous Comments
                  </a>
                </p>
              </div>
            </div>
          ): null
        }
        {
          comments.comments.map(comment => {
            return (
              <Comment
                key={comment.id}
                comment={comment}
                contextActions={commentContextActions[comment._links.self.href]}
                onDelete={this._deleteComment}
                onFetchContextActions={cmd.fetchContextActions}
                />
            );
          })
        }
        {
          comments.commentable ? (
            <div className="BigModalSidebarComment">
              <div className="BigModalSidebarComment-user">
                <i className="fa fa-comment-o"></i>
              </div>
              <div className="BigModalSidebarComment-content">
                <form className="BigModalSidebarComment-form" onSubmit={this._submitContent}>
                  <input type="text" placeholder="Enter a comment" value={this.state.newComment} onChange={this._newCommentChanged}/>
                </form>
              </div>
            </div>
          ): null
        }
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  let keys = [];
  if (ownProps.comments.comments) {
    keys = ownProps.comments.comments.map(c => c._links.self.href);
  }
  return {
    commentContextActions: pick(state.feed.contextActions, keys),
  };
}

export default connect(mapStateToProps, null)(FeedModalSidebarCommentsArea);

class Comment extends React.PureComponent {
  _delete = () => {
    const {comment, onDelete} = this.props;
    onDelete(comment);
  };

  _fetchContextActions = () => {
    const {comment, onFetchContextActions} = this.props;
    onFetchContextActions(comment._links.context_actions.href, comment._links.self.href);
  };

  render() {
    const {comment} = this.props;
    return (
      <div className="BigModalSidebarComment" key={comment.id}>
        {/* <CommentDropdown comment={comment} contextActions={this.props.contextActions} onDelete={this._delete} onFetch={this._fetchContextActions} /> */}
        <div className="BigModalSidebarComment-user">
          <a href={comment._embedded.author._links.self.href}>
            <img src={comment._embedded.author._links.profile_picture.href} />
          </a>
        </div>
        <div className="BigModalSidebarComment-content">
          <a href={comment._embedded.author._links.self.href}>{comment._embedded.author.display_name}</a>
          {' '}<span dangerouslySetInnerHTML={{__html: comment.content_html}}></span>
          <div className="BigModalSidebarComment-timestamp">{ngDateFilter(comment.created_at, 'MMM d, y h:mm a')}</div>
        </div>
      </div>
    );
  }
}

class CommentDropdown extends React.PureComponent {
  render() {
    const {comment, contextActions} = this.props;
    return (
      <div className="post-comment-dropdown dropdown">
        <a className="post-comment-dropdown-toggle" data-toggle="dropdown" onClick={this.props.onFetch}>
          <i className="fa fa-angle-down txt-middle" style={{lineHeight: 'inherit'}}></i>
        </a>
        <ul className="dropdown-menu">
          {
            !contextActions ? (
              <li className="loading-gif"></li>
            ): null
          }
          {
            contextActions ? (
              <li>
                <a data-user-voice-report-abuse={comment._links.self.href}><span className="fa fa-flag"></span> Report Abuse</a>
              </li>
            ): null
          }
          {
            contextActions && contextActions.delete ? (
              <li>
                <a onClick={this.props.onDelete}>
                  <i className="fa fa-times"></i> Delete
                </a>
              </li>
            ): null
          }
        </ul>
      </div>
    );
  }
}
