import React from 'react';
import './TagsList.css';

class TagsList extends React.PureComponent {
  render() {
    const {data, onRemove} = this.props;
    return (
      <div className="TagsList">
        {
          data.tags.length > 0 ? (
            <span className="fa fa-tags"></span>
          ): null
        }
        {
          data.tags.map((tag, idx) => {
            const last = idx === data.tags.length - 1;
            let prefix = null;
            if (idx > 0 && last) {
              prefix = ' and ';
            } else if (idx > 0) {
              prefix = ', ';
            } else {
              prefix = ' ';
            }
            return (
              <TagsListItem key={idx} prefix={prefix} tag={tag} onRemove={onRemove} />
            );
          })
        }
      </div>
    );
  }
}

export default TagsList;

class TagsListItem extends React.PureComponent {
  _remove = () => {
    if (!this.props.onRemove || !this.props.tag.removable) {
      return;
    }
    this.props.onRemove(this.props.tag);
  };

  render() {
    const {prefix, tag, onRemove} = this.props;
    return (
      <span>
        {prefix}
        {
          tag.href ? (
            <a href={tag.href}>{tag.name}</a>
          ): (
            tag.name
          )
        }
        {
          onRemove && tag.removable ? (
            <a onClick={this._remove} style={{color: 'inherit'}}> (remove)</a>
          ): null
        }
      </span>
    );
  }
}
