import React from 'react';
import ReactDOM from 'react-dom';
import SupportRequestForm from './SupportRequestForm.js';
import './SupportRequestModal.css';

export default class SupportRequestModal extends React.PureComponent {
  _close = (event) => {
    event.stopPropagation();
    if (event.target !== ReactDOM.findDOMNode(this._inner)) {
      return;
    }
    if (this.props.onCloseRequested) {
      this.props.onCloseRequested();
    }
  };

  render() {
    const offsetY = window.pageYOffset;
    const bodyHeight = document.body.scrollHeight;
    const windowHeight = window.innerHeight;
    return (
      <div className="SupportRequestModal" style={{height: bodyHeight}}>
        <div ref={n => this._inner = n} className="SupportRequestModalInnerWrapper" style={{top: offsetY, height: windowHeight}}>
          <div className="SupportRequestModalInner">
            <SupportRequestForm ref={n => this._box = n} {...this.props} onClose={this.props.onCloseRequested} />
          </div>
        </div>
      </div>
    );
  }
}
