import React from 'react';
import ReactDOM from 'react-dom';
import * as browsers from '../misc/browsers.js';
import './ShareBox.css';

function generateSummaryFromGameScore(edit) {
  const game = edit._embedded.game;
  let summary = `${game.away_team_short_name} ${game.away_team_score}, ${game.home_team_short_name} ${game.home_team_score}`;
  if (edit.extended_info_summary) {
    summary = summary + ' - ' + edit.extended_info_summary;
  }
  const lines = [
    summary,
    game.level ? `${game.level} ${game.sport.name}` : `${game.sport.name}`,
  ];
  if (edit.notes) {
    lines.push(edit.notes);
  }
  lines.push('Follow: ' + game._links.blst.href);
  return lines.join('\n');
}

function getFacebookAppId() {
  var m = document.querySelector('meta[property="fb:app_id"]');
  if (m) {
    return m.getAttribute('content');
  }
  return null;
}

function guessTitle(url) {
  if (!url) {
    return 'Share';
  }
  if (url.match(/\/articles\/[\d]+/)) {
    return 'Share this article';
  }
  if (url.match(/\/calendar_events\/[\d]+/)) {
    return 'Share this event';
  }
  if (url.match(/\/games\/[\d]+/)) {
    return 'Share this game';
  }
  if (url.match(/\/mvps\/[\d]+/)) {
    return 'Share this MVP';
  }
  if (url.match(/\/pictures\/[\d]+/)) {
    return 'Share this photo';
  }
  if (url.match(/\/posts\/[\d]+/)) {
    return 'Share this post';
  }
  if (url.match(/\/teams\/[\d]+/)) {
    return 'Share this team';
  }
  if (url.match(/\/videos\/[0-9A-Z]+/)) {
    return 'Share this video';
  }
  if (url.match(/\/video_links\/[\d]+/)) {
    return 'Share this video';
  }
  return 'Share';
}

export default class ShareBox extends React.PureComponent {
  static defaultProps = {
    title: undefined,
    extra: undefined,
  }

  _click = (event) => {
    event.preventDefault();
    if (document.createRange && window.getSelection) {
      const selection = window.getSelection();
      selection.removeAllRanges();
      const range = document.createRange();
      range.selectNode(event.target);
      selection.addRange(range);
      document.execCommand('copy');
    }
    return false;
  }

  _copyToClipboard = (event) => {
    event.preventDefault();
    const url = event.target.getAttribute('href');
    const textarea = document.createElement('textarea');
    textarea.value = url;
    textarea.style.position = 'fixed';
    textarea.style.left = '-9999px';
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  };

  render() {
    const {url, extra} = this.props;
    let title = this.props.title;
    if (!title) {
      title = guessTitle(url);
    }
    const facebookUrl = `https://www.facebook.com/dialog/share?&display=popup&app_id=${getFacebookAppId()}&href=${encodeURIComponent(url)}`;
    let {mailBody, mailSubject} = this.props;
    if (!mailBody) {
      mailBody = url;
    }
    if (!mailSubject) {
      mailSubject = '';
    }
    let mailUrl = `mailto:?subject=${encodeURIComponent(mailSubject)}&body=${encodeURIComponent(mailBody)}`;
    let twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`;
    let smsUrl = undefined;
    if (browsers.isIos()) {
      smsUrl = `sms:''&body=${encodeURIComponent(mailBody)}`;
    } else if (browsers.isAndroid()) {
      smsUrl = `sms:''?body=${encodeURIComponent(mailBody)}`;
    }
    if (url.match(/\/games\/[\d]+/) && extra) {
      // assuming that extra is a game score edit
      mailSubject = 'Score Update';
      mailBody = generateSummaryFromGameScore(extra);
      mailUrl = `mailto:?subject=${encodeURIComponent(mailSubject)}&body=${encodeURIComponent(mailBody)}`;
      twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(mailSubject + '\n' + mailBody + '\n' + '#blastathletics')}`;
      if (browsers.isIos()) {
        smsUrl = `sms:''?body=${encodeURIComponent(mailSubject + '\n' + mailBody)}`;
      } else if (browsers.isAndroid()) {
        smsUrl = `sms:''?body=${encodeURIComponent(mailSubject + '\n' + mailBody)}`;
      }
    }
    return (
      <div className="ShareBox">
        <div className="ShareBoxTitle">
          {title}
        </div>
        {
          <div className="ShareBoxAction">
            <a className="btn ShareBoxCopyButton btn-success text-uppercase  px-2" href={url} target="_blank" onClick={this._copyToClipboard}>Copy Link</a>
          </div>
        }
        <div className="ShareBoxAction">
          <a className="ShareBoxLink" href={facebookUrl} target="_blank">Facebook</a>
        </div>
        <div className="ShareBoxAction">
          <a className="ShareBoxLink" href={twitterUrl} target="_blank">Twitter</a>
        </div>
        {
          smsUrl ? (
            <div className="ShareBoxAction">
              <a className="ShareBoxLink" href={smsUrl} target="_blank">Text (SMS)</a>
            </div>
          ): null
        }
        <div className="ShareBoxAction">
          <a className="ShareBoxLink" href={mailUrl} target="_blank">Email</a>
        </div>
        {
          this.props.onClose ? (
            <div className="ShareBoxAction ShareBoxAction--cancel">
              <a className="ShareBoxCancel" onClick={this.props.onClose}>Cancel</a>
            </div>
          ): null
        }
      </div>
    );
  }
}

class ShareBoxInput extends React.PureComponent {
  componentDidMount = () => {
    this._node.focus();
    this._node.setSelectionRange(0, this._node.value.length, 'backward');
  };

  render() {
    return (
      <input ref={n => this._node = n} className="ShareBoxInput" readOnly value={this.props.href} />
    );
  }
}
