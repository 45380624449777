import React from "react";

import './VideoPanel.css';

class VideoPanel extends React.PureComponent {
  render() {
    const { video, onPlay } = this.props;
    const ready = !!video.ready_at;
    const sd =
      ready && !(video.aspect_ratio >= 1.7 || video.aspect_ratio < 1.0);
    const aspectClass = `Post-contentVideo u-videoThumbnail ${ sd ? "AspectRatioBox--ratio-4-3" : "AspectRatioBox--ratio-16-9" }`;
    if (!ready) {
      return (
        <div className={aspectClass}>
          <div className="AspectRatioBoxPlaceholder AspectRatioBoxPlaceholder--noBorder" style={{ backgroundColor: "#777", color: "#ddd" }} >
            <a className="AspectRatioBoxPlaceholder-text text-center" style={{ color: "inherit" }} onClick={this.props.onClick} >
              <div style={{ fontSize: "400%" }}>
                <span className="fa fa-clock-o" />
              </div>
              This video is being processed. Check back later, or click here to
              refresh the page.
            </a>
          </div>
        </div>
      );
    }
    return (
      <a className={aspectClass} onClick={this.props.onClick}>
        <img src={video._links.thumbnail.href} />
      </a>
    );
  }
}

export default VideoPanel;
