import React from 'react';
import cx from 'classnames';
import Portal from '../../../misc/Portal';
import SupportRequestModal from '../../../supportRequests/SupportRequestModal';
import * as util from '../../../util';

class FeedPostDropdown extends React.PureComponent {
  state = {
    open: false,
    reporting: false,
  };

  componentDidMount() {
    document.addEventListener('click', this._handleBodyClick, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this._handleBodyClick, true);
  }

  _closeReport = () => {
    this.setState({reporting: false});
  };

  _contextDeletePost = () => {
    const {post, cmd, actionUrls} = this.props;
    if (confirm('Are you sure?')) {
      cmd.deletePost(post, actionUrls['delete'].href);
    }
  };

  _contextMuteUser = () => {
    const {post, cmd, actionUrls} = this.props;
    cmd.muteUser(post, actionUrls.mute.href);
  };

  _contextUnmuteUser = () => {
    const {post, cmd, actionUrls} = this.props;
    cmd.unmuteUser(post, actionUrls.unmute.href);
  };

  _fetch = (event) => {
    if (this.state.open) {
      this.setState({open: false});
      return;
    }
    this.props.cmd.fetchContextActions(this.props.post._links.context_actions.href);
    this.setState({open: true});
  };

  _generateContextActions = (post, cmd, actionUrls) => {
    if (!actionUrls || actionUrls._fetching) {
      return null;
    }
    let contextActions = {};
    if (actionUrls['delete']) {
      contextActions['delete'] = {
        title: actionUrls['delete'].title,
        callback: this._contextDeletePost,
      };
    }
    if (actionUrls.mute) {
      contextActions.mute = {
        title: actionUrls.mute.title,
        callback: this._contextMuteUser,
      };
    }
    if (actionUrls.unmute) {
      contextActions.unmute = {
        title: actionUrls.unmute.title,
        callback: this._contextUnmuteUser,
      };
    }
    return contextActions;
  };

  _handleBodyClick = (event) => {
    if (!this.state.open) {
      return;
    }
    if (!util.isAncestor(event.target, this._dropdown)) {
      event.preventDefault();
      this.setState({open: false});
      return;
    }
  };

  _report = (event) => {
    this.setState({open: false, reporting: true});
  };

  render() {
    const dropdownClass = cx('dropdown', {
      open: this.state.open,
    });
    let options = null;
    if (this.state.open) {
      const actions = this._generateContextActions(this.props.post, this.props.cmd, this.props.actionUrls);
      if (!actions) {
        options = (
          <ul className="unstyled dropdown-menu">
            <li className="txt-center"><span className="loading-gif"></span></li>
          </ul>
        );
      } else {
        let deletePost = null;
        if (actions.delete) {
          deletePost = (
            <li><a onClick={actions.delete.callback}><span className="fa fa-times"></span> {actions.delete.title}</a></li>
          );
        }
        let mute = null;
        if (actions.mute) {
          mute = (
            <li>
              <a onClick={actions.mute.callback}><span className="fa fa-volume-off"></span> {actions.mute.title}</a>
            </li>
          );
        }
        let unmute = null;
        if (actions.unmute) {
          unmute = (
            <li>
              <a onClick={actions.unmute.callback}><span className="fa fa-volume-up"></span> {actions.unmute.title}</a>
            </li>
          );
        }
        options = (
          <ul className="unstyled dropdown-menu">
            <li>
              <a onClick={this._report} className="Report"><span className="fa fa-flag"></span> Report Abuse</a>
            </li>
            {mute}
            {unmute}
            {deletePost}
          </ul>
        );
      }
    }
    let report = null;
    if (this.state.reporting) {
      let url = '';
      if (this.props.post.activity) {
        url = this.props.post.activity._links.self.href;
      } else {
        url = this.props.post._links.self.href;
      }
      report = (
        <Portal>
          <SupportRequestModal url={url} onCloseRequested={this._closeReport} />
        </Portal>
      );
    }

    return (
      <span className={dropdownClass} ref={n => this._dropdown = n}>
        <a className="MoreMenu dropdown-toggle" onClick={this._fetch}><i className="fa-solid fa-ellipsis"></i></a>
        {options}
        {report}
      </span>
    );
  }
}

export default FeedPostDropdown;
