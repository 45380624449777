import React from 'react';
import ReactDOM from 'react-dom';
import preloaded from '../preloaded.js';
import * as browsers from '../misc/browsers.js';
import * as qf from '../misc/qf.js';
import './SupportRequestForm.css';

function getFacebookAppId() {
  var m = document.querySelector('meta[property="fb:app_id"]');
  if (m) {
    return m.getAttribute('content');
  }
  return null;
}

function guessTitle(url) {
  if (!url) {
    return 'Report';
  }
  if (url.match(/\/articles\/[\d]+/)) {
    return 'Report this article';
  }
  if (url.match(/\/calendar_events\/[\d]+/)) {
    return 'Report this event';
  }
  if (url.match(/\/games\/[\d]+/)) {
    return 'Report this game';
  }
  if (url.match(/\/mvps\/[\d]+/)) {
    return 'Report this MVP';
  }
  if (url.match(/\/pictures\/[\d]+/)) {
    return 'Report this photo';
  }
  if (url.match(/\/posts\/[\d]+/)) {
    return 'Report this post';
  }
  if (url.match(/\/teams\/[\d]+/)) {
    return 'Report this team';
  }
  if (url.match(/\/videos\/[0-9A-Z]+/)) {
    return 'Report this video';
  }
  if (url.match(/\/video_links\/[\d]+/)) {
    return 'Report this video';
  }
  return 'Report';
}

export default class SupportRequestForm extends React.PureComponent {
  static defaultProps = {
    showUrl: false,
    title: undefined,
  }

  state = {
    failed: false,
    params: {
      href: this.props.url,
      name: '',
      email: '',
      description: '',
      request_type: 'abuse_report',
    },
    submitting: false,
    successful: false,
  };

  _click = (event) => {
    event.preventDefault();
    if (document.createRange && window.getSelection) {
      const selection = window.getSelection();
      selection.removeAllRanges();
      const range = document.createRange();
      range.selectNode(event.target);
      selection.addRange(range);
      document.execCommand('copy');
    }
    return false;
  }

  _done = (event) => {
    if (this.props.onClose) {
      this.props.onClose();
      return;
    }
    if (this.props.returnTo) {
      window.location.href = this.props.returnTo;
      return;
    }
    window.close();
  };

  _noClick = (event) => {
    event.preventDefault();
    return false;
  };

  _paramChanged = (event) => {
    this.setState({
      params: {
        ...this.state.params,
        [event.target.name]: event.target.value,
      },
    });
  };

  _submit = (event) => {
    event.preventDefault();
    if (this.state.submitting) {
      return;
    }
    this.setState({failed: false, submitting: true, successful: false});
    qf.postJSON('/support_requests', {...this.state.params}).then(data => {
      this.setState({submitting: false, successful: true});
    }).catch(error => {
      this.setState({failed: true, submitting: false});
    });
  };

  render() {
    const {url} = this.props;
    let title = this.props.title;
    if (!title) {
      title = guessTitle(url);
    }
    return (
      <form className="SupportRequestForm" onSubmit={this._submit}>
        <div className="SupportRequestFormTitle">
          {title}
        </div>
        <div className="SupportRequestFormBody">
          {
            this.props.showUrl ? (
              <div className="form-group">
                <label className="control-label">
                  Page to Report
                </label>
                <p className="form-control-static" style={{overflow: 'hidden'}}>
                  {this.props.url}
                </p>
              </div>
            ): null
          }
          {
            !preloaded.current_user_id ? (
              <div className="form-group">
                <label className="control-label">
                  Your Name (optional)
                </label>
                <input type="text" className="form-control" name="name" value={this.state.params.name} disabled={this.state.successful} onChange={this._paramChanged} />
              </div>
            ): null
          }
          {
            !preloaded.current_user_id ? (
              <div className="form-group">
                <label className="control-label">
                  Your Email Address
                </label>
                <input type="email" className="form-control" name="email" value={this.state.params.email} disabled={this.state.successful} onChange={this._paramChanged} />
              </div>
            ): null
          }
          <div className="form-group">
            <label className="control-label">
              Description {this.props.url ? <span>(optional)</span> : null}
            </label>
            <textarea className="form-control" name="description" autoFocus value={this.state.params.description} required={!this.props.url} disabled={this.state.successful} onChange={this._paramChanged} />
          </div>
        </div>
        <div className="SupportRequestFormActions">
          <div className="row">
            <div className="col-xs-6 col-xs-push-6">
              <div className="SupportRequestFormAction">
                {
                  this.state.successful ? (
                    <a className="btn btn-link" onClick={this._done}>Done</a>
                  ): (
                    <button type="submit" className="btn btn-link">Submit</button>
                  )
                }
              </div>
            </div>
            <div className="col-xs-6 col-xs-pull-6">
              {
                this.state.failed ? (
                  <div className="SupportRequestFormAction">
                    <a className="SupportRequestFormCancel text-danger" onClick={this._done}>There was an error</a>
                  </div>
                ): this.state.successful ? (
                  <div className="SupportRequestFormAction text-success">
                    <div className="btn btn-txt"><strong>Sent</strong></div>
                  </div>
                ): this.props.onClose ? (
                  <div className="SupportRequestFormAction">
                    <a className="btn btn-txt SupportRequestFormCancel" onClick={this._done}>Cancel</a>
                  </div>
                ): this.props.returnTo ? (
                  <div className="SupportRequestFormAction">
                    <a className="btn btn-txt SupportRequestFormCancel" href={this.props.returnTo}>Cancel</a>
                  </div>
                ): null
              }
            </div>
          </div>
        </div>
      </form>
    );
  }
}

class SupportRequestFormInput extends React.PureComponent {
  componentDidMount = () => {
    this._node.focus();
    this._node.setSelectionRange(0, this._node.value.length, 'backward');
  };

  render() {
    return (
      <input ref={n => this._node = n} className="SupportRequestFormInput" readOnly value={this.props.href} />
    );
  }
}
