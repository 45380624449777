import React from 'react';
import ReactDOM from 'react-dom';
import ShareBox from './ShareBox.js';
import './ShareModal.css';

export default class ShareModal extends React.PureComponent {
  _close = (event) => {
    event.stopPropagation();
    if (event.target !== ReactDOM.findDOMNode(this._inner)) {
      return;
    }
    if (this.props.onCloseRequested) {
      this.props.onCloseRequested();
    }
  };

  render() {
    const offsetY = window.pageYOffset;
    const bodyHeight = document.body.scrollHeight;
    const windowHeight = window.innerHeight;
    return (
      <div className="ShareModal" style={{height: bodyHeight}}>
        <div ref={n => this._inner = n} className="ShareModalInnerWrapper" style={{top: offsetY, height: windowHeight}} onClick={this._close}>
          <div className="ShareModalInner">
            <ShareBox ref={n => this._box = n} {...this.props} onClose={this.props.onCloseRequested} />
          </div>
        </div>
      </div>
    );
  }
}
