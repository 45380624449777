import React from 'react';

export default class ErrorBoundary extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  componentDidCatch(error, info) {
    this.setState({hasError: true});
    /* eslint-disable no-console */
    if (console && console.error) {
      console.error(error);
    }
    /* eslint-enable no-console */
  }

  render() {
    if (this.state.hasError) {
      return null;
    }
    return this.props.children;
  }
}
