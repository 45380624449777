import update from 'immutability-helper';
import Actions from './actions.js';

export function allPosts(state = [], action) {
  switch (action.type) {
  case Actions.POST_DELETED:
    {
      let idx = -1;
      for (let i = 0; i < state.length; i++) {
        if (state[i].id === action.data.id) {
          idx = i;
          break;
        }
      }
      if (idx >= 0) {
        return update(state, {$splice: [[idx, 1]]});
      }
      return state;
    }
  case Actions.POSTS_RECEIVED:
    state = state.concat(action.data._embedded.posts);
    state.sort(function (a, b) {
      if (a.id < b.id) {
        return 1;
      } else if (a.id > b.id) {
        return -1;
      } else {
        return 0;
      }
    });
    return state;
  }
  return state;
}

export function comments(state = {}, action) {
  if (action.type === Actions.COMMENT_DELETED) {
    const data = state[action.data.parent];
    let idx = -1;
    for (let i = 0; i < data.comments.length; i++) {
      if (data.comments[i].id === action.data.id) {
        idx = i;
        break;
      }
    }
    return update(state, {
      [action.data.parent]: {
        comments: {$splice: [[idx, 1]]},
      },
    });
  }
  if (action.type === Actions.COMMENTS_RECEIVED) {
    return update(state, {[action.data.key]: {comments: {$push: action.data.comments}}});
  }
  if (action.type === Actions.PREVIOUS_COMMENTS_RECEIVED) {
    const earliest = action.data.comments.length > 0 ? action.data.comments[action.data.comments.length - 1] : null;
    const hi = state[action.data.key].hi;
    const jk = earliest ? earliest.created_at : null;
    const comments = action.data.comments.slice().reverse();
    return update(state, {
      [action.data.key]: {
        comments: {$unshift: comments},
        hi: {$set: (!hi) || (jk && jk <= hi) ? jk : hi},
      },
    });
  }
  if (action.type === Actions.POSTS_RECEIVED) {
    const q = {};
    Object.keys(action.data.comments).forEach(key => {
      const data = action.data.comments[key];
      const first = data.comments.length > 0 ? data.comments[0] : null;
      if (!first) {
        q[key] = {$merge: {hi: null}};
        return;
      }
      q[key] = {$merge: {hi: first.created_at}};
    });
    return update(state, {$merge: update(action.data.comments, q)});
  }
  return state;
}

export function contextActions(state = {}, action) {
  if (action.type === Actions.CONTEXT_ACTIONS_REQUESTED) {
    return update(state, {$merge: {
      [action.data.key]: {_fetching: true},
    }});
  } else if (action.type === Actions.CONTEXT_ACTIONS_RECEIVED) {
    return update(state, {
      [action.data.key]: {$set: action.data.data._links},
    });
  }
  return state;
}

export function embedded(state = {}, action) {
  if (action.type === Actions.ITEM_DESCRIPTION_UPDATED) {
    const item = state[action.data.key];
    if (!item) {
      return state;
    }
    return update(state, {
      [action.data.key]: {
        description: {$set: action.data.description},
        description_html: {$set: action.data.description_html},
      },
    });
  }
  if (action.type === Actions.POSTS_RECEIVED) {
    const next = {};
    action.data._embedded.posts.forEach(post => {
      if (!post._embedded.actor) {
        return;
      }
      if (!state[post._embedded.actor._links.self.href]) {
        next[post._embedded.actor._links.self.href] = post._embedded.actor;
      }
    });
    action.data._embedded.posts.forEach(post => {
      if (post.activity_type === 'Upload') {
        if (!state[post.activity._links.self.href]) {
          next[post.activity._links.self.href] = post.activity;
        }
        if (post.activity._links.creator && !state[post.activity._links.creator.href]) {
          next[post.activity._links.creator.href] = post.activity._embedded.creator;
        }
      } else if (post.activity_type === 'PictureTag') {
        const photo = post.activity._embedded.picture;
        if (!state[photo._links.self.href]) {
          next[photo._links.self.href] = photo;
        }
        if (photo._links.creator && !state[photo._links.creator.href]) {
          next[photo._links.creator.href] = photo._embedded.creator;
        }
      } else if (post.activity_type === 'ThankYouVideo') {
        if (post.activity._embedded.video_link) {
          const video = post.activity._embedded.video_link;
          if (!state[video._links.self.href]) {
            next[video._links.self.href] = video;
          }
          if (video._links.creator && !state[video._links.creator.href]) {
            next[video._links.creator.href] = video._embedded.creator;
          }
        } else {
          const video = post.activity._embedded.video;
          if (!state[video._links.self.href]) {
            next[video._links.self.href] = video;
          }
          if (video._links.creator && !state[video._links.creator.href]) {
            next[video._links.creator.href] = video._embedded.creator;
          }
        }
      } else if (post.activity_type === 'Video' || post.activity_type === 'VideoLink' ) {
        if (!state[post.activity._links.self.href]) {
          next[post.activity._links.self.href] = post.activity;
        }
        if (post.activity._links.creator && !state[post.activity._links.creator.href]) {
          next[post.activity._links.creator.href] = post.activity._embedded.creator;
        }
      } else if (post.activity_type === 'VideoLinkTag') {
        const video = post.activity._embedded.video_link;
        if (!state[video._links.self.href]) {
          next[video._links.self.href] = video;
        }
        if (video._links.creator && !state[video._links.creator.href]) {
          next[video._links.creator.href] = video._embedded.creator;
        }
      } else if (post.activity_type === 'VideoTag') {
        const video = post.activity._embedded.video;
        if (!state[video._links.self.href]) {
          next[video._links.self.href] = video;
        }
        if (video._links.creator && !state[video._links.creator.href]) {
          next[video._links.creator.href] = video._embedded.creator;
        }
      } else if (post.activity_type === 'VideoVital') {
        const vital = post.activity;
        const video = post.activity._embedded.video;
        const videoLink = post.activity._embedded.video_link;
        if (videoLink) {
          if (!state[videoLink._links.self.href]) {
            next[videoLink._links.self.href] = videoLink;
          }
        } else {
          if (!state[video._links.self.href]) {
            next[video._links.self.href] = video;
          }
        }
        if (video._links.creator && !state[video._links.creator.href]) {
          next[video._links.creator.href] = video._embedded.creator;
        }
      }
    });
    return update(state, {$merge: next});
  }
  return state;
}

export function fetching(state = false, action) {
  switch (action.type) {
  case Actions.POSTS_REQUESTED:
    return true;
  case Actions.POSTS_RECEIVED:
    return false;
  }
  return state;
}

export function likeUrls(state = {}, action) {
  if (action.type !== Actions.POSTS_RECEIVED) {
    return state;
  }
  const memo = {};
  action.data._embedded.posts.forEach(post => {
    if (!post.activity_type) {
      memo[post._links.self.href] = {like: post._links.like.href, unlike: post._links.unlike.href};
    } else if (post.activity_type === 'Upload' || post.activity_type === 'Video' || post.activity_type === 'VideoLink' || post.activity_type === 'Article' || post.activity_type === 'Mvp') {
      memo[post.activity._links.self.href] = {like: post.activity._links.like.href, unlike: post.activity._links.unlike.href};
    } else if (post.activity_type === 'PictureTag') {
      memo[post.activity._links.picture.href] = {like: post.activity._embedded.picture._links.like.href, unlike: post.activity._embedded.picture._links.unlike.href};
    } else if (post.activity_type === 'ThankYouVideo') {
      if (post.activity._links.video_link) {
        memo[post.activity._links.video_link.href] = {like: post.activity._embedded.video_link._links.like.href, unlike: post.activity._embedded.video_link._links.unlike.href};
      } else {
        memo[post.activity._links.video.href] = {like: post.activity._embedded.video._links.like.href, unlike: post.activity._embedded.video._links.unlike.href};
      }
    } else if (post.activity_type === 'VideoLinkTag') {
      memo[post.activity._links.video_link.href] = {like: post.activity._embedded.video_link._links.like.href, unlike: post.activity._embedded.video_link._links.unlike.href};
    } else if (post.activity_type === 'VideoTag') {
      memo[post.activity._links.video.href] = {like: post.activity._embedded.video._links.like.href, unlike: post.activity._embedded.video._links.unlike.href};
    } else if (post.activity_type === 'VideoVital') {
      const video = post.activity._embedded.video;
      const videoLink = video._embedded.video_link;
      if (videoLink) {
        memo[videoLink._links.self.href] = {like: videoLink._links.like.href, unlike: videoLink._links.unlike.href};
      } else {
        memo[video._links.self.href] = {like: video._links.like.href, unlike: video._links.unlike.href};
      }
    }
  });
  return update(state, {$merge: memo});
}

export function likes(state = {}, action) {
  if (action.type === Actions.ITEM_LIKED) {
    return update(state, {[action.data.key]: {$set: action.data.prev + 1}});
  }
  if (action.type === Actions.ITEM_UNLIKED) {
    return update(state, {[action.data.key]: {$set: action.data.prev - 1}});
  }
  if (action.type !== Actions.POSTS_RECEIVED) {
    return state;
  }
  let memo = action.data._embedded.posts.reduce((memo, post) => {
    if (!post.activity_type) {
      memo[post._links.self.href] = post.likes_cache;
    } else if (post.activity_type === 'Upload' || post.activity_type === 'Video' || post.activity_type === 'VideoLink' || post.activity_type === 'Article' || post.activity_type === 'Mvp') {
      if (post.activity._links.self && post.activity.hasOwnProperty('likes_cache')) {
        memo[post.activity._links.self.href] = post.activity.likes_cache;
      }
    } else if (post.activity_type === 'PictureTag') {
      memo[post.activity._links.picture.href] = post.activity._embedded.picture.likes_cache;
    } else if (post.activity_type === 'ThankYouVideo') {
      if (post.activity._links.video_link) {
        memo[post.activity._links.video_link.href] = post.activity._embedded.video_link.likes_cache;
      } else {
        memo[post.activity._links.video.href] = post.activity._embedded.video.likes_cache;
      }
    } else if (post.activity_type === 'VideoLinkTag') {
      memo[post.activity._links.video_link.href] = post.activity._embedded.video_link.likes_cache;
    } else if (post.activity_type === 'VideoTag') {
      memo[post.activity._links.video.href] = post.activity._embedded.video.likes_cache;
    } else if (post.activity_type === 'VideoVital') {
      const video = post.activity._embedded.video;
      const videoLink = video._embedded.video_link;
      if (videoLink) {
        memo[videoLink._links.self.href] = videoLink.likes_cache;
      } else {
        memo[video._links.self.href] = video.likes_cache;
      }
    }
    return memo;
  }, {});
  return update(memo, {$merge: state});
}

export function liking(state = {}, action) {
  if (action.type === Actions.ITEM_LIKING) {
    return update(state, {[action.data.key]: {$set: true}});
  }
  if (action.type === Actions.ITEM_LIKED || action.type === Actions.ITEM_UNLIKED) {
    return update(state, {[action.data.key]: {$set: false}});
  }
  return state;
}

export function myLikes(state = {}, action) {
  if (action.type === Actions.ITEM_LIKED) {
    return update(state, {[action.data.key]: {$set: true}});
  }
  if (action.type === Actions.ITEM_UNLIKED) {
    return update(state, {[action.data.key]: {$set: false}});
  }
  if (action.type !== Actions.POSTS_RECEIVED) {
    return state;
  }
  if (Object.keys(action.data.likes).length === 0) {
    return state;
  }
  return update(state, {$merge: action.data.likes});
}

export function newerCountUrl(state = '', action) {
  if (action.type !== Actions.POSTS_RECEIVED) {
    return state;
  }
  if (action.data._links.newer_count) {
    if (!state || state < action.data._links.newer_count.href) {
      return action.data._links.newer_count.href;
    }
  }
  return state;
}

export function newerUrl(state = '', action) {
  if (action.type !== Actions.POSTS_RECEIVED) {
    return state;
  }
  const data = action.data;
  if (data._links.newer) {
    if (!state || state < data._links.newer.href) {
      return data._links.newer.href;
    }
  } else {
    return '';
  }
  return state;
}

export function newPosts(state = 0, action) {
  if (action.type === Actions.NEW_POSTS_UNSEEN) {
    return action.data;
  }
  return state;
}

export function olderUrl(state = '', action) {
  if (action.type !== Actions.POSTS_RECEIVED) {
    return state;
  }
  const data = action.data;
  if (data._links.older) {
    if (!state || state > data._links.older.href) {
      return data._links.older.href;
    }
  } else {
    return '';
  }
  return state;
}

export function openModal(state = null, action) {
  if (action.type === Actions.MODAL_OPENED) {
    return {
      key: action.data.key,
      postId: action.data.postId,
      scrollTop: action.data.scrollTop,
    };
  }
  if (action.type === Actions.MODAL_CLOSED) {
    return null;
  }
  return state;
}

export function prevWindowLocation(state = '', action) {
  if (action.type === Actions.MODAL_OPENED) {
    if (action.data.oldWindowLocation) {
      return action.data.oldWindowLocation;
    }
    return state;
  }
  if (action.type === Actions.MODAL_CLOSED) {
    return '';
  }
  return state;
}

export function showcased(state = {}, action) {
  if (action.type === Actions.ITEM_UNSHOWCASED) {
    return update(state, {
      [action.data.key]: {$set: false},
    });
  }
  if (action.type === Actions.ITEM_SHOWCASED) {
    return update(state, {
      [action.data.key]: {$set: true},
    });
  }
  if (action.type === Actions.CONTEXT_ACTIONS_RECEIVED) {
    return update(state, {
      [action.data.key]: {$set: !!action.data.data.showcased},
    });
  }
  return state;
}

export function updatedViewCounts(state = {}, action) {
  if (action.type !== Actions.ITEM_VIEWED) {
    return state;
  }
  if (state[action.data.key]) {
    return state;
  }
  return update(state, {[action.data.key]: {$set: action.data.value}});
}

export function videosPlayed(state = {}, action) {
  if (action.type !== Actions.VIDEO_PLAYED) {
    return state;
  }
  if (state[action.data.key]) {
    return state;
  }
  return update(state, {[action.data.key]: {$set: true}});
}
