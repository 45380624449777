import $ from 'jquery';
import * as qf from '../../../misc/qf';
import Actions from './actions.js';

function nop(dispatch, getState) {}

export function closeModal () {
  if (window.onpopstate) {
    window.history.back();
    return nop;
  }
  return closeModal2();
}

function closeModal2 () {
  return (dispatch, getState) => {
    const state = getState();
    if (!state.feed.openModal) {
      return;
    }
    if (window.history) {
      window.onpopstate = null;
    }
    const scrollTop = state.feed.openModal.scrollTop;
    dispatch({
      type: Actions.MODAL_CLOSED,
      data: {},
    });
    if (scrollTop) {
      document.body.scrollTop = scrollTop;
    }
  };
}

export function comment (key, text) {
  return (dispatch, getState) => {
    const state = getState();
    const meta = state.feed.comments[key];
    if (!meta || !meta.commentable) {
      return;
    }
    const params = {
      content: text,
    };
    qf.postJSON(meta.commentable, params).then(data => {
      dispatch({
        type: Actions.COMMENTS_RECEIVED,
        data: {
          key: key,
          comments: [data],
        },
      });
    });
  };
}

export function deleteComment (comment, parentKey) {
  return (dispatch, getState) => {
    qf.deleteJSON(comment._links.self.href).then(() => {
      dispatch({
        type: Actions.COMMENT_DELETED,
        data: {
          id: comment.id,
          parent: parentKey,
        },
      });
    });
  };
}

export function deletePhoto (picture, url) {
  return (dispatch, getState) => {
    qf.deleteJSON(url).then(() => {
      const state = getState().feed;
      const posts = state.allPosts.filter(post => {
        if (post.activity_type === 'Upload' && post.activity_id === picture.id) {
          return true;
        }
        if (post.activity_type === 'PictureTag' && post.activity._links.picture.href === picture._links.self.href) {
          return true;
        }
        return false;
      });
      posts.forEach(post => {
        dispatch({
          type: Actions.POST_DELETED,
          data: {
            id: post.id,
          },
        });
      });
      if (state.openModal && state.openModal.key == picture._links.self.href) {
        dispatch(closeModal());
      }
    });
  };
}

export function deletePost (post, url) {
  return (dispatch, getState) => {
    const feed = getState().feed;
    if (post.activity_type === 'Upload') {
      dispatch(deletePhoto(feed.embedded[post.activity._links.self.href], url));
      return;
    }
    if (post.activity_type === 'VideoLink') {
      dispatch(deleteVideo(feed.embedded[post.activity._links.self.href], url));
      return;
    }
    qf.deleteJSON(url).then(() => {
      dispatch({
        type: Actions.POST_DELETED,
        data: {
          id: post.id,
        },
      });
    });
  };
}

export function deleteVideo(video, url) {
  return (dispatch, getState) => {
    qf.deleteJSON(url).then(() => {
      const feed = getState().feed;
      const posts = feed.allPosts.filter(post => {
        if ((post.activity_type === 'VideoLink' || post.activity_type === 'Thank You Video') && post.activity_id === video.id) {
          return true;
        }
        if (post.activity_type === 'VideoLinkTag' && post.activity._links.video_link.href === video._links.self.href) {
          return true;
        }
        if (post.activity_type === 'VideoTag' && post.activity._links.video.href === video._links.self.href) {
          return true;
        }
        if (post.activity_type === 'VideoVital') {
          if (post.activity._links.video && post.activity._links.video.href === video._links.self.href) {
            return true;
          } else if (post.activity._links.video_link && post.activity._links.video_link.href === video._links.self.href) {
            return true;
          }
        }
        return false;
      });
      posts.forEach(post => {
        dispatch({
          type: Actions.POST_DELETED,
          data: {
            id: post.id,
          },
        });
      });
      if (feed.openModal && feed.openModal.key == video._links.self.href) {
        dispatch(closeModal());
      }
    });
  };
}

export function fetchContextActions(url, key) {
  if (!key) {
    key = url;
  }
  return (dispatch, getState) => {
    dispatch({
      type: Actions.CONTEXT_ACTIONS_REQUESTED,
      data: {
        key: key,
      },
    });
    qf.getJSON(url).then(data => {
      dispatch({
        type: Actions.CONTEXT_ACTIONS_RECEIVED,
        data: {
          key: key,
          data: data,
        },
      });
    });
  };
}

export function fetchNewPostsCount () {
  return (dispatch, getState) => {
    const state = getState();
    if (!state.feed.newerCountUrl) {
      return;
    }
    qf.getJSON(state.feed.newerCountUrl).then(data => {
      dispatch({
        type: Actions.NEW_POSTS_UNSEEN,
        data: data.newer,
      });
    });
  };
}

export function fetchNewerPosts () {
  return (dispatch, getState) => {
    const state = getState();
    if (!state.feed.newerUrl) {
      return;
    }
    dispatch(fetchPosts(state.feed.newerUrl));
    dispatch({
      type: Actions.NEW_POSTS_UNSEEN,
      data: 0,
    });
  };
}

export function fetchOlderPosts() {
  return (dispatch, getState) => {
    const state = getState();
    if (!state.feed.olderUrl) {
      return;
    }
    dispatch(fetchPosts(state.feed.olderUrl));
  };
}

export function fetchPreviousComments (key) {
  return (dispatch, getState) => {
    const state = getState();
    const meta = state.feed.comments[key];
    if (!meta || !meta.source || !(meta.earliest < meta.hi)) {
      return;
    }
    $.get(meta.source, {before: meta.hi}).done(data => {
      const comments = data.comments;
      comments.reverse();
      dispatch({
        type: Actions.PREVIOUS_COMMENTS_RECEIVED,
        data: {
          key: key,
          comments: comments,
        },
      });
    });
  };
}

export function fetchPosts(url) {
  return (dispatch, getState) => {
    const state = getState();
    if (state.feed.fetching) {
      return;
    }
    dispatch({
      type: Actions.POSTS_REQUESTED,
    });
    qf.getJSON(url).then(data => {
      dispatch({
        type: Actions.POSTS_RECEIVED,
        data: data,
      });
    });
  };
}

export function like (key) {
  return (dispatch, getState) => {
    const state = getState();
    if (state.feed.liking[key]) {
      return null;
    }
    const prev = state.feed.likes[key];
    if (state.feed.myLikes[key]) {
      const url = state.feed.likeUrls[key].unlike;
      dispatch({type: Actions.ITEM_LIKING, data: {key: key}});
      qf.post(url).then(() => {
        dispatch({
          type: Actions.ITEM_UNLIKED,
          data: {
            prev: prev,
            key: key,
          },
        });
      });
      return;
    }
    const url = state.feed.likeUrls[key].like;
    qf.post(url).then(() => {
      dispatch({type: Actions.ITEM_LIKING, data: {key: key}});
      dispatch({
        type: Actions.ITEM_LIKED,
        data: {
          prev: prev,
          key: key,
        },
      });
    });
  };
}

export function muteUser (post, url) {
  return (dispatch, getState) => {
    qf.post(url).then(() => {
      dispatch({
        type: Actions.USER_MUTED,
        data: post,
      });
      const contextActionsUrl = post._links.context_actions.href;
      qf.getJSON(contextActionsUrl).then(data => {
        dispatch({
          type: Actions.CONTEXT_ACTIONS_RECEIVED,
          data: {
            key: contextActionsUrl,
            data: data,
          },
        });
      });
    });
  };
}

export function openModal (key, postId, newWindowLocation) {
  return (dispatch, getState) => {
    const state = getState();
    if (state.feed.openModal) {
      return;
    }
    const oldWindowLocation = window.location.href;
    if (window.history.pushState && newWindowLocation) {
      window.history.pushState({}, '', newWindowLocation);
      window.onpopstate = event => {
        dispatch(closeModal2());
      };
    }
    dispatch({
      type: Actions.MODAL_OPENED,
      data: {
        key: key,
        postId: postId,
        scrollTop: document.body.scrollTop,
        newWindowLocation: newWindowLocation,
        oldWindowLocation: oldWindowLocation,
      },
    });
  };
}

export function showcase (url, key) {
  if (!key) {
    key = url;
  }
  return (dispatch, getState) => {
    const state = getState();
    const showcased = state.feed.showcased[key];
    const params = {};
    const req = !showcased ? qf.post(url, params) : qf.del(url, params);
    req.then(data => {
      if (showcased) {
        dispatch({
          type: Actions.ITEM_UNSHOWCASED,
          data: {
            key: key,
          },
        });
        return;
      }
      dispatch({
        type: Actions.ITEM_SHOWCASED,
        data: {
          key: key,
        },
      });
  }).catch( err => {
    console.error(err);
  });
  };
}

export function unmuteUser (post, url) {
  return (dispatch, getState) => {
    qf.post(url).then(() => {
      dispatch({
        type: Actions.USER_UNMUTED,
        data: post,
      });
      const contextActionsUrl = post._links.context_actions.href;
      qf.getJSON(contextActionsUrl).then(data => {
        dispatch({
          type: Actions.CONTEXT_ACTIONS_RECEIVED,
          data: {
            key: contextActionsUrl,
            data: data,
          },
        });
      });
    });
  };
}

export function updateDescription (url, key, description) {
  return (dispatch, getState) => {
    qf.postJSON(url, {name: description}).then(data => {
      dispatch({
        type: Actions.ITEM_DESCRIPTION_UPDATED,
        data: {
          key: key,
          description: data.description,
          description_html: data.description_html,
        },
      });
    });
  };
}

export function videoPlayed (url) {
  return (dispatch, getState) => {
    const state = getState();
    if (state.feed.videosPlayed[url]) {
      return;
    }
    qf.post(url + '/viewed').then(() => {
      dispatch({
        type: Actions.VIDEO_PLAYED,
        data: {
          key: url,
        },
      });
    });
  };
}

export function viewed (url, key, current) {
  if (!key) {
    key = url;
  }
  return (dispatch, getState) => {
    const state = getState();
    if (state.feed.updatedViewCounts[key]) {
      return;
    }
    qf.post(url).then(() => {
      dispatch({
        type: Actions.ITEM_VIEWED,
        data: {
          key: key,
          value: current + 1,
        },
      });
    });
  };
}
