import React from 'react';

class FeedPostAvatar extends React.PureComponent {
  render() {
    let post = this.props.post;
    return (
      <a href={post._links.actor.href}>
        <img src={post._links.actor_profile_picture.href} className="Post-profileImg" />
      </a>
    );
  }
}

export default FeedPostAvatar;
