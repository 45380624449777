import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as cmd from './feed_redux/commands';

class FeedContainer extends React.Component {
  render() {
    const Feed = this.props.feedComponent;
    return (
      <Feed
        cmd={this.props.cmd}
        comments={this.props.feed.comments}
        contextActions={this.props.feed.contextActions}
        embeddedObjects={this.props.feed.embedded}
        fetching={this.props.feed.fetching}
        likes={this.props.feed.likes}
        modal={this.props.feed.openModal}
        myLikes={this.props.feed.myLikes}
        newPosts={this.props.feed.newPosts}
        posts={this.props.feed.allPosts}
        />
    );
  }
}

function mapState (state) {
  return state;
}

function mapDispatch (dispatch) {
  return {
    cmd: bindActionCreators(cmd, dispatch),
  };
}

export default connect(mapState, mapDispatch)(FeedContainer);
