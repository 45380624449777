import React from "react";

import "./VideoVital.css";

class CombineVideoVitalCard extends React.PureComponent {
  render() {
    const { video, videoLink, videoVital, onOpenModal } = this.props;
    return (
      <div className="CombineVideoVital">
        <div className="d-flex align-items-center Combine-info">
          <a href={videoVital._links.combine_team.href}>
            <img
              src={videoVital._links.combine_team["data-avatar"]}
              className="Post-profileImg"
            />
          </a>
          <div className="Post-info flex-fill">
            {videoVital._links.combine.title}
            <br />
            <a href={videoVital._links.combine.href}>
              {videoVital._links.combine_team.title}
            </a>
          </div>
        </div>
        {videoVital._links.user &&
        videoVital.user_id !== videoVital.creator_id ? (
          <div className="d-flex align-items-center User-info">
            <a href={videoVital._links.user.href}>
              <img
                src={videoVital._links.user["data-avatar"]}
                className="Post-profileImg"
              />
            </a>
            <div className="Post-info flex-fill">
              <div>
                <a href={videoVital._links.user.href}>
                  {videoVital._links.user.title}
                </a>
              </div>
              <div className="bold">
                {videoLink ? videoLink.description : video.description}
              </div>
            </div>
          </div>
        ) : videoVital._links.child ? (
          <div className="d-flex align-items-center User-info">
            <a href={videoVital._links.child.href} >
              <img
                src={videoVital._links.child["data-avatar"]}
                className="Post-profileImg"
              />
            </a>
            <div className="Post-info flex-fill">
              <div>
                <a href={videoVital._links.child.href}>
                  {videoVital._links.child.title}
                </a>
              </div>
              <div className="bold">
                {videoLink ? videoLink.description : video.description}
              </div>
            </div>
          </div>
        ) : videoVital._links.team_member ? (
          <div className="d-flex align-items-center User-info">
            <img
              src={videoVital._links.team_member["data-avatar"]}
              className="Post-profileImg"
            />
            <div className="Post-info flex-fill">
              <div>{videoVital._links.team_member.title}</div>
              <div className="bold">
                {videoLink ? videoLink.description : video.description}
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex align-items-center User-info">
            <div className="Post-profileImg d-flex align-items-center justify-content-center">
              <i class="fa-regular fa-clock-nine"></i>
            </div>
            <div className="Post-info flex-fill">
              <div
                className="truncatable-line"
                style={{ fontWeight: "bold", lineHeight: "40px" }}
              >
                {videoLink ? videoLink.description : video.description}
              </div>
            </div>
          </div>
        )}
        <a
          className="AspectRatioBoxViewport"
          onClick={onOpenModal}
        >
          <div className="AspectRatioBox AspectRatioBox--ratio-16-9">
            <img
              src={
                videoLink ? videoLink.thumbnail : video._links.thumbnail.href
              }
            />
            <div className="AspectRatioBoxViewport-overlay2 d-flex justify-content-center align-items-center">
              <img
                className="video-vital-post-overlay"
                src="/static/img/vitals/video_vitals_logo.png"
              />
            </div>
          </div>
        </a>
      </div>
    );
  }
}

export default CombineVideoVitalCard;
