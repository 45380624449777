import cx from 'classnames';
import Portal from '../../../misc/Portal';
import React from 'react';
import ShareModal from '../../../share/ShareModal';
// import './FeedPostBottomBar.css';

class FeedPostBottomBar extends React.Component {
  state = {
    sharing: false,
  }

  _closeSharing = () => {
    this.setState({sharing: false});
  }

  _likeToggle = () => {
    if (!this.props.activityUrl) {
      return;
    }
    this.props.onLike(this.props.activityUrl);
  };

  _share = () => {
    this.setState({sharing: true});
  }

  render() {
    const {buyUrl, nextLink, shareHref, liked, likes, commentsLength} = this.props;
    let shareBtn = null;
    if (shareHref) {
      shareBtn = (
        <button onClick={this._share}>
          <i className="fa fa-share-alt"></i> <span className="d-none d-md-inline">Share</span>
        </button>
      );
    }

    let buyBtn = null;
    if (buyUrl) {
      buyBtn = (
        <a href={buyUrl} target="_blank">
          <i className="fa fa-picture-o"></i> <span className="d-none d-md-inline">Buy Photo</span>
        </a>
      );
    }

    let nextBtn = null;
    if (nextLink) {
      nextBtn = (
        <div className="toright">
          <ul>
            <li className="gonext">
              <a href={nextLink.href} className="link-effect">
                {nextLink.title} &gt;&gt;
              </a>
            </li>
          </ul>
        </div>
      );
    }

    let likeBtn = null;
    if (likes !== undefined) {
      likeBtn = (
        <button onClick={this._likeToggle} className={`Social-Like${likes ? ' FeedPostBottomBarLikeButton--liked' : ''}`}>
          <i className={`fa-regular fa-thumbs-up${likes ? ' FeedPostBottomBarLikeButton--liked' : ''}`}></i> <span className="d-none d-md-inline">Like</span> ({likes})
        </button>
      );
    }

    let sharing = null;
    if (this.state.sharing) {
      sharing = (
        <Portal>
          <ShareModal url={this.props.shareHref} extra={this.props.shareExtra} onCloseRequested={this._closeSharing} />
        </Portal>
      );
    }

    let commentsBtn = null;
    if (commentsLength || commentsLength === 0) {
      commentsBtn = (
        <button className="Social-comment">
          <i className="fa-regular fa-comment-dots"></i> <span className="d-none d-md-inline">Comments</span> ({commentsLength})
        </button>
      )
    }

    return (
      <div className="Post-social d-flex">
        {shareBtn}
        {buyBtn}
        {likeBtn}
        {commentsBtn}
        {nextBtn}
        {sharing}
      </div>
    );
  }
}

export default FeedPostBottomBar;
