import React from "react";

import "./VideoVital.css";

class VideoVitalCard extends React.PureComponent {
  render() {
    const { video, videoLink, videoVital, onOpenModal } = this.props;
    return (
      <div className="VideoVital">
        {videoVital._links.team ? (
          <div className="d-flex align-items-center Combine-info">
            <a href={videoVital._links.team.href} >
              <img src={videoVital._links.team["data-avatar"]} className="Post-profileImg" />
            </a>
            <div className="Post-info flex-fill">
              <a href={videoVital._links.team.href}>
                {videoVital._links.team.title}
              </a>
              <br />
              {videoVital._links.team["data-description"]}
            </div>
          </div>
        ) : null}
        {videoVital._links.user && videoVital.user_id !== videoVital.creator_id ? (
          <div className="d-flex align-items-center User-info">
            <a href={videoVital._links.user.href} >
              <img src={videoVital._links.user["data-avatar"]} className="Post-profileImg" />
            </a>
            <div className="Post-info flex-fill">
              <div>
                <a href={videoVital._links.user.href}>
                  {videoVital._links.user.title}
                </a>
              </div>
              <div className="bold">
                {videoLink ? videoLink.description : video.description}
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex align-items-center User-info">
            <div className="Post-profileImg d-flex align-items-center justify-content-center">
              <i className="fa-regular fa-clock-nine"></i>
            </div>
            <div className="Post-info flex-fill">
              <div
                className="truncatable-line"
                style={{ fontWeight: "bold", lineHeight: "40px" }}
              >
                {videoLink ? videoLink.description : video.description}
              </div>
            </div>
          </div>
        )}
        <a
          className="AspectRatioBoxViewport"
          onClick={onOpenModal}
        >
          <div className="AspectRatioBox AspectRatioBox--ratio-16-9">
            <img
              className="h-100 w-100"
              src={videoLink ? videoLink.thumbnail : video._links.thumbnail.href}
            />
            <div className="AspectRatioBoxViewport-overlay2 d-flex justify-content-center align-items-center">
              <img
                className="video-vital-post-overlay"
                src="/static/img/vitals/video_vitals_logo.png"
              />
            </div>
          </div>
        </a>
      </div>
    );
  }
}

export default VideoVitalCard;
