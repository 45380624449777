import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import BigModal from './BigModal.jsx';
import FeedModalSidebar from './FeedModalSidebar.jsx';
import * as cmd from '../../feed_redux/commands';
import ngDateFilter from '../../../../ngDateFilter';
// import './FeedModal.css';

const TIMESTAMP_FORMAT = 'MMM d, y \'at\' h:mma';

class PhotoFeedModal extends React.Component {
  componentDidMount() {
    const {cmd, picture} = this.props;
    cmd.fetchContextActions(picture._links.context_actions.href, picture._links.self.href);
    cmd.viewed(picture._links.viewed.href, picture._links.self.href, picture.view_count);
  }

  _delete = () => {
    const {cmd, contextActions, picture} = this.props;
    if (!contextActions.delete || !confirm('Are you sure?')) {
      return;
    }
    cmd.deletePhoto(picture, contextActions.delete.href);
  };

  render() {
    const {cmd, comments, contextActions, creator, liked, likes, showcased, picture, views} = this.props;
    const modalContentOverlay = (
      <div className="BigModalOptionsPane">
        <div className="BigModalOptionsPane-content">
          <div className="dropdown">
            <a className="dropdown-toggle" data-bs-toggle="dropdown" id="dropdown">
              <i className="fa-regular fa-ellipsis txt-middle" style={{ lineHeight: "inherit" }} ></i>
            </a>
            <ul className="unstyled no-padding no-margin dropdown-menu" aria-labelledby="dropdown">
              {
                !contextActions ? (
                  <li>
                    <span className="loading-gif" />
                  </li>
                ): null
              }
              {
                contextActions ? (
                  <li>
                    <a data-user-voice-report-abuse={picture._links.self.href}>
                      <span className="fa fa-flag"/> Report Abuse
                    </a>
                  </li>
                ): null
              }
              {
                contextActions && contextActions.download ? (
                  <li>
                    <a href={contextActions.download.href} download>
                      <span className="fa fa-cloud-download" /> Download
                    </a>
                  </li>
                ): null
              }
              {
                contextActions && contextActions.delete ? (
                  <li>
                    <a onClick={this._delete}>
                      <span className="fa fa-times" /> Delete this Photo
                    </a>
                  </li>
                ): null
              }
            </ul>
          </div>
        </div>
      </div>
    );
    let buyBtn = null;
    if (picture._links.buy) {
      buyBtn = (
        <div style={{position: 'absolute', right: 5, bottom: 5, lineHeight: 1}}>
          <a className="btn btn-white-overlay" href={picture._links.buy.href} target="_blank">
            <span className="fa fa-picture-o" /> Buy
          </a>
        </div>
      );
    }
    const modalContent = (
      <div className="BigModalStageContentContainer">
        <img className="BigModalStageContent" key={picture._links.self.href} src={picture._links.v_large.href} />
        {buyBtn}
      </div>
    );
    const formattedDate = ngDateFilter(picture.created_at, TIMESTAMP_FORMAT);
    const modalSidebar = (
      <FeedModalSidebar
        activityUrl={picture._links.self.href}
        author={creator}
        cmd={cmd}
        contextActions={contextActions}
        comments={comments}
        description={picture.description}
        destination={picture._links.destination}
        liked={liked}
        likes={likes}
        showcased={showcased}
        tagsUrl={picture._links.tags.href}
        timestamp={formattedDate}
        views={views}
        onLike={cmd.like}
        onShowcase={cmd.showcase}
        />
    );
    return (
      <BigModal
        content={modalContent}
        contentHeight={picture.height}
        contentWidth={picture.width}
        contentOverlay={modalContentOverlay}
        sidebar={modalSidebar}
        onCloseRequested={cmd.closeModal} />
    );
  }
}

function mapStateToProps(state, ownProps) {
  const updatedViewCount = state.feed.updatedViewCounts[ownProps.picture._links.self.href];
  return {
    contextActions: state.feed.contextActions[ownProps.picture._links.self.href],
    showcased: state.feed.showcased[ownProps.picture._links.self.href],
    views: updatedViewCount ? updatedViewCount : ownProps.picture.view_count,
  };
}

function mapDispatch (dispatch) {
  return {
    cmd: bindActionCreators(cmd, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatch)(PhotoFeedModal);
