import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';
import './TagEditor.css';

export default class TagEditor extends React.Component {
  static defaultProps = {
    disabled: false,
    tagAllNoun: 'Athletes',
  };

  state = {
    term: '',
    searchResults: null,
    cursor: undefined,
  };

  _add = (result) => {
    this.props.onAdd(result);
    this.setState({
      term: '',
      searchResults: null,
      cursor: undefined,
    });
  };

  _onContainerClicked = (event) => {
    ReactDOM.findDOMNode(this.input).focus();
  };

  _onKeyDown = (event) => {
    switch (event.keyCode) {
    case 38: // up arrow
      this._prevSelection();
      event.preventDefault();
      break;
    case 40: // down arrow
    case 9: // tab
      this._nextSelection();
      event.preventDefault();
      break;
    case 13: // enter
      event.preventDefault();
      if (this.state.cursor !== undefined) {
        this._add(this.state.searchResults[this.state.cursor]);
      }
      break;
    case 27: // escape
      this._onSearchCleared();
      event.preventDefault();
      break;
    }
  };

  _onRemove = (index, event) => {
    event.stopPropagation();
    this.props.onRemove(index);
    this._onContainerClicked();
  };

  _onSearch = (term) => {
    if (term) {
      $.getJSON(this.props.searchUrl, {name: term}).done(data => {
        this.setState({
          searchResults: data,
          cursor: undefined,
        });
      });
    } else {
      this._onSearchCleared();
    }
  };

  _onSearchCleared = () => {
    setTimeout(() => {
      this.setState({
        searchResults: null,
        cursor: undefined,
      });
    });
  };

  _nextSelection = () => {
    let nextCursor = this.state.cursor;
    if (this.state.cursor === undefined) {
      nextCursor = 0;
    } else if (this.state.searchResults && this.state.cursor < (this.state.searchResults.length - 1)) {
      nextCursor++;
    }
    this.setState({cursor: nextCursor});
  };

  _prevSelection = () => {
    this.setState(prevState => {
      if (prevState.cursor !== undefined && prevState.cursor > 0) {
        return {
          cursor: prevState.cursor - 1,
        };
      } else {
        return {
          cursor: prevState.cursor,
        };
      }
    });
  };

  _termChanged = (event) => {
    let text = event.target.value;
    if (text === this.state.term) {
      return;
    }
    this.setState({term: text, searchResults: null});
    clearTimeout(this._timer);
    if (text && text.length > 1) {
      this._timer = setTimeout(() => {
        this._onSearch(text);
      }, 500);
    } else {
      this._onSearchCleared();
    }
  };

  render() {
    let tags = this.props.tags.map((t, idx) => {
      let removeBtn = null;
      if (t.removable) {
        removeBtn = (
          <span className="TagEditor-tagRemoveBtn" onClick={this._onRemove.bind(this, idx)}>
            <i className="fa fa-times"></i>
          </span>
        );
      }
      return (
        <span key={idx} className="TagEditor-tag">
          {t.name}
          {' '}
          {removeBtn}
        </span>
      );
    });

    let dropdown = null;
    if (this.state.searchResults) {
      let items = this.state.searchResults.map((r, idx) => {
        let className = cx({active: this.state.cursor === idx});
        return (
          <li key={idx} className={className}>
            <a onClick={this._add.bind(null, r)} style={{cursor: 'pointer'}}>
              {r.name}
            </a>
          </li>
        );
      });
      if (items.length === 0) {
        items = [(
          <li key={0}>No results</li>
        )];
      }
      dropdown = (
        <ul className="dropdown-menu" style={{display: 'block'}}>
          {items}
        </ul>
      );
    }

    let tagAllNoun = this.props.tagAllNoun;
    if (!tagAllNoun) {
      tagAllNoun = 'Athletes';
    }

    let tagPlaceholder = '';
    if (!this.props.disabled) {
      tagPlaceholder = 'Name or #Number (#2, #80)';
    } else {
      tagPlaceholder = ''
    }

    return (
      <div className={cx('TagEditor', {'TagEditor--disabled': this.props.disabled})}>
        <div className="TagEditor-tags clearfix" onClick={this._onContainerClicked}>
          {tags}
          <div className="TagEditor-inputWrapper">
            <input
              ref={n => this.input = n}
              className="TagEditor-input"
              type="text"
              autoFocus
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              disabled={this.props.disabled || this.props.submitting}
              spellCheck="false"
              value={this.state.term}
              onChange={this._termChanged}
              onKeyDown={this._onKeyDown}
              placeholder={tagPlaceholder} />
            {dropdown}
          </div>
        </div>
        {
          this.props.onTagAll ? (
            <div>
              <button className="btn btn-link" onClick={this.props.onTagAll}>Tag All {tagAllNoun}</button> {this.props.submitting ? (<span className="btn btn-txt loading-gif"></span>) : null}
            </div>
          ): null
        }
      </div>
    );
  }
}
